import jwt_decode from "jwt-decode";

export interface AuthInformation {
    jwt: string;
    username: string,
    group: number,
    userId: number,
    expiration: number
}
export interface AuthInformationMinimal {
    jwt: string;
    username: string
}
interface JwtInformation {
    group_id: number,
    user_id: number,
    exp: number
}
interface listFunction {
    [key: string]: (info: AuthInformation|null)=>void
}


class AuthStorage {

    static listFunction : listFunction = {};

    public static addListener(fn: (info: AuthInformation|null) => void, key: string)
    {
        this.listFunction[key] = fn;
        //console.log(this.listFunction)
    }

    public static removeListener(key: string)
    {
        delete this.listFunction[key];
        console.log(this.listFunction)
        //this.listFunction.push(fn);
    }

    getUserData(): AuthInformation|null {
        const data = this.getData('user.information');

        if (null === data) {
           return null;
        }
        const parsedData = JSON.parse(data)

        return this.constructAuthInformation(parsedData)
    }
    removeUserData(): void {
        this.setData('user.information', null);
    }

    setUserData(param: AuthInformationMinimal) {
        this.setData('user.information', param)
    }

    getData = (key: string): string | null => {
        return localStorage.getItem(key);
    };

    setData(key: string, data : AuthInformationMinimal| null) {
        if (data !== null) {
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            localStorage.removeItem(key);
        }
        const authInfo = this.constructAuthInformation(data);
        Object.keys(AuthStorage.listFunction).map(
            (key)=> AuthStorage.listFunction[key](authInfo)
        )
    }

    constructAuthInformation = (info: AuthInformationMinimal|null): AuthInformation | null => {
        if (null === info) {
            return null;
        }
        const claims = jwt_decode<JwtInformation>(info.jwt)

        return {
            username: info.username,
            jwt: info.jwt,
            group: claims.group_id,
            userId: claims.user_id,
            expiration: claims.exp
        };
    }

}


export default AuthStorage;
