import {
    Button,
    ButtonGroup,
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead, TablePagination,
    TableRow
} from "@mui/material";
import React from "react";

// mui
import {styled} from "@mui/material/styles";
import Alert from '@mui/material/Alert';

// icons
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";


interface ActionProperty {
    action: string,
    color: any,
    icon: any,
}
export class ViewActionProperty implements ActionProperty{
    action: string = 'view';
    color = 'info';
    icon = (<VisibilityIcon fontSize="inherit" />);
}
export class EditActionProperty implements ActionProperty{
    action: string = 'edit';
    color = 'inherit';
    icon = (<EditIcon fontSize="inherit" />);
}

export class DeleteActionProperty implements ActionProperty{
    action: string = 'delete';
    color = 'error';
    icon = (<DeleteForeverIcon fontSize="inherit" />);
}

export interface TableComponentContent {
    key: string|number,
    data: any[]
}

export interface TableComponentParameter {
    columns: string[]
    actions: string|ActionProperty[],
    actionsHandler: (action: string, key: string|number) => void
    content: TableComponentContent[]
}

export default function TableComponent(props: TableComponentParameter) {

    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);

    function computeList(): TableComponentContent[]
    {
        return props.content.slice(rowsPerPage * page, rowsPerPage * (page+1))
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table" size={"small"}>
                <TableHead>
                    <TableRow>
                        {props.columns.map((columnName: string, index) => (<StyledTableCell key={index}>{columnName}</StyledTableCell>))}
                        {props.actions !== 'none' &&
                            <StyledTableCell>Actions</StyledTableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {computeList().map((contentRow, indexRow) => (
                        <StyledTableRow key={indexRow}>
                            {contentRow.data.map((content, index) => (
                                <StyledTableCell key={index} component="th" scope="row">{content}</StyledTableCell>
                            ))}
                            {Array.isArray(props.actions) &&
                                <StyledTableCell>
                                    {props.actions.map((action, indexAction) => {
                                        return (
                                            <Button key={indexAction} variant="contained" color={action.color} onClick={()=> props.actionsHandler( action.action, contentRow.key)}>
                                                {action.icon}
                                            </Button>
                                        )
                                    })}
                                </StyledTableCell>
                            }
                            {props.actions === 'recipe' &&
                                <StyledTableCell>
                                    <ButtonGroup disableElevation variant="contained">
                                        <Button variant="contained" color="info" onClick={()=> props.actionsHandler( 'view', contentRow.key)}>
                                            <VisibilityIcon fontSize="inherit" />
                                        </Button>
                                        <Button variant="contained" color="inherit" onClick={()=> props.actionsHandler( 'edit', contentRow.key)}>
                                            <EditIcon fontSize="inherit" />
                                        </Button>
                                        {/*<Button variant="contained" color="success" onClick={()=> props.actionsHandler( 'plan', contentRow.key)}>
                                            <EventIcon fontSize="inherit" />
                                        </Button>*/}
                                        <Button variant="contained" color="error"  onClick={()=> props.actionsHandler( 'delete', contentRow.key)}>
                                            <DeleteForeverIcon fontSize="inherit" />
                                        </Button>
                                    </ButtonGroup>
                                </StyledTableCell>
                            }
                            {props.actions === 'all' &&
                            <StyledTableCell>
                                <ButtonGroup disableElevation variant="contained">
                                    <Button variant="contained" color="info" onClick={()=> props.actionsHandler( 'view', contentRow.key)}>
                                        <VisibilityIcon fontSize="inherit" />
                                    </Button>
                                    <Button variant="contained" color="inherit" onClick={()=> props.actionsHandler( 'edit', contentRow.key)}>
                                        <EditIcon fontSize="inherit" />
                                    </Button>
                                    <Button variant="contained" color="error"  onClick={()=> props.actionsHandler( 'delete', contentRow.key)}>
                                        <DeleteForeverIcon fontSize="inherit" />
                                    </Button>
                                </ButtonGroup>
                            </StyledTableCell>
                            }
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            {props.content.length === 0 ? <Alert icon={false} severity="success">No result</Alert>: '' }
            {props.content.length !== 0 && (props.content.length/rowsPerPage)>1 &&
                <TablePagination
                    component="div"
                    count={props.content.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
        </TableContainer>
    )

}




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
