import React from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import IngredientRepository, {Ingredient} from "../../Repository/Ingredient";
import TableComponent, {TableComponentContent, ViewActionProperty} from "../../Components/TableComponent";

export default function IngredientViewCard(props: RouteComponentProps<{id: string}>) {
    const id  = parseInt(props.match.params.id);
    const ingredientState = React.useRef<Ingredient>()
    const ingredient = ingredientState.current
    const history = useHistory();

    let [isLoaded, setIsLoaded] = React.useState<boolean>(false);


    React.useEffect(()=>{
        IngredientRepository.getRepository()
            .findOne(id)
            .then((result) => {
                ingredientState.current = result;
                setIsLoaded(true)
            })
        ;
    }, [id])


    if (!isLoaded || typeof ingredient === 'undefined') {
        return (
            <div id={"RecipeCard"}>
                loading
            </div>
        );
    }

    const content = ingredient.recipes.map<TableComponentContent>((recipe): TableComponentContent => {
            return {key: recipe.id, data: [recipe.name,],}
        }
    )

    const handleActions = function (action: string, key: string|number): void {
        if (action === 'view') {
            history.push('/recipes/'+ key + '/view');
        }
    }


    return (
        <div id={"IngredientCard"}>
            <h1>Ingredient : {ingredient.name}</h1>
            <p>
                Quantité et unité usuelle:  <strong>{ingredient.defaultQuantity} {ingredient.unit.name}</strong>{ingredient.description !=='' &&
                <React.Fragment>
                    <br/>Description: <strong>{ingredient.description}</strong>
                </React.Fragment>
            }
            </p>

            <TableComponent  columns={['Recette',]} actions={[new ViewActionProperty()]} actionsHandler={handleActions} content={content}/>
        </div>
    )
}

