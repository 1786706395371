
export interface DataInformation {
}

interface DataStorageInformation {
    expiration: number
    content: DataInformation
}

export default class DataStorage<DataInformation> {

    // to test
    static listFunction: ((info: any)=>void)[] = [];

    public static addListener<DataInformation>(fn: (info: DataInformation|null) => void)
    {
        this.listFunction.push(fn);
    }

    removeData(key: string): void {
        this.setData(key, null, null);
    }

    getData(key: string): DataInformation|null  {
        const rawData = localStorage.getItem(key);
        if (rawData === null) {
            return null;
        }
        const dataStorage = JSON.parse(rawData) as DataStorageInformation;
        if (dataStorage.expiration < Math.round(new Date().getTime() / 1000)) {
            return null;
        }

        return dataStorage.content as DataInformation;
    }

    setData(key: string, data : DataInformation| null, lifetime: number|null) {
        if (data !== null) {
            const storageData = {
                expiration: Math.round(new Date().getTime() / 1000) + 3600,
                content: data
            }
            localStorage.setItem(key, JSON.stringify(storageData));
        } else {
            localStorage.removeItem(key);
        }
        DataStorage.listFunction.map(
            (fn) => fn(data)
        )
    }

}
