import HttpClientHelper from "../Common/HttpClientHelper";


export interface CategoriesById {
    [key: number]: Category;
}
export interface CategoryFlattenIdsName {
    id: number,
    ids: number[],
    name: string
}

export class Category {
    public children: Category[] = [];
    constructor(
        public id: number,
        public name: string,
        public parentId: number|null = null,
        dataChild: any = []
    ) {
        this.children = dataChild.map((child: any)=>{
            return new Category(child.id, child.name, child.parentId, child.children || [])
        })
    }

}



class CategoriesRepository {

    private static instance: CategoriesRepository;

    static getRepository(): CategoriesRepository {

        if (!CategoriesRepository.instance) {
            CategoriesRepository.instance = new CategoriesRepository();
        }

        return CategoriesRepository.instance;
    }

    static findTree(format: string= 'tree'): Promise<Category[]> {
        return CategoriesRepository.find(format);
    }
    static find = (format: string|null = null): Promise<Category[]> => {
        const curFormat = format || 'default';

        return HttpClientHelper.callWithAuth('GET', '/categories' + (curFormat==='tree'?'?format=tree':''), {})
            .then((data: any[]) => {
                return data.map((category: any) => {
                    return new Category(category.id, category.name, category.parentId, category.children || [])
                })
            })
    }

    getCatsWithIdKey = ():Promise<CategoriesById> => {
        return CategoriesRepository.find(null).then(
            (categories: Category[]) => {
                let data :CategoriesById = {}
                categories.map((cat: Category) => data[cat.id] = cat)
                return data;
            }
        )
        /*
        return this.find(null).map((categories: any) => {

        })*/
    }

    /*
    getCatsTree = () =>{
        return new Promise(
            (resolve)=> {
                this.find('tree').then((categories) => {
                    resolve(categories);
                })
            }
        );
    }
    */

    getCatsTreeAsList = (): Promise<CategoryFlattenIdsName[]> => {
        return CategoriesRepository.find('tree')
            .then((categories)=>{
                const recFunction = (cat: Category, level: number): CategoryFlattenIdsName[] => {
                    if(cat.children === null) {
                        cat.children = [];
                    }
                    const ids = [cat.id]
                    cat.children.map((child: any)=>ids.push(child.id));
                    const result = [{
                        id: cat.id,
                        ids: ids,
                        name: cat.name.padStart(cat.name.length+level, '_')
                    }]

                    cat.children.map((child: any) => {
                        return recFunction(child, level+1).map((childResult)=>result.push(childResult));
                    })

                    return result;
                }
                let result: CategoryFlattenIdsName[] = [];
                categories.map((cat: any)=>{
                    return recFunction(cat, 0).map((childResult)=>result.push(childResult))
                })
                return result;
        })
    }
    /*
    static update(id: number, data: any){
        if (id > 0) {
            return BackEnd.callHttp('PATCH', '/categories/'+id, data);
        } else {
            return BackEnd.callHttp('POST', '/categories', data);
        }
    }
    static delete(id: number){
        return BackEnd.callHttp('DELETE', '/categories/' + id);
    }*/
}



export default CategoriesRepository;
