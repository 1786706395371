import React from 'react';
import './App.css';
import {
    AppBar, Box,
    Button,
    CssBaseline, IconButton,
    Toolbar,
    Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AuthStorage, {AuthInformation} from "./Common/AuthStorage";
import AuthCard from "./Modules/AuthCard";
import RecipeListCard from "./Modules/Recipe/RecipeListCard";
import {
    BrowserRouter as Router, Redirect,
    Route,
    Switch as RouterSwitch
} from 'react-router-dom';
import SideBar from "./Common/SideBar";
import IngredientListCard from "./Modules/Ingredient/IngredientListCard";
import {styled} from '@mui/material/styles';
import UnitListCard from "./Modules/Unit/UnitListCard";
import CategoryListCard from "./Modules/Category/CategoryListCard";
import PlanningListCard from "./Modules/Planning/PlanningListCard";
import RecipeViewCard from "./Modules/Recipe/RecipeViewCard";
import RecipeEditCard from "./Modules/Recipe/RecipeEditCard";
import IngredientViewCard from "./Modules/Ingredient/IngredientViewCard";
import UnitViewCard from "./Modules/Unit/UnitViewCard";
import ErrorBoundary from "./Common/ErrorHandler";
import WipCard from "./Modules/Wip/WipCard";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    marginTop: `64px`,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    width: `100%`,
    [theme.breakpoints.only("xs")]: {
        marginLeft: `0px`,
        padding: `4px`
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

function App() {

    const [open, setOpen] = React.useState(false);
    const storage = new AuthStorage()
    let userData = storage.getUserData()

    let checkIsLogged = (data: AuthInformation|null):boolean => {
        return data !== null && data.jwt.length > 0 &&
            data.expiration > Math.round(new Date().getTime() / 1000);
    }



    const [isLoggedIn, setIsLoggedIn] = React.useState(checkIsLogged(userData));

    React.useEffect(()=>{
        //should remove listener
        AuthStorage.addListener((info: AuthInformation|null)=> {
            setIsLoggedIn(checkIsLogged(info));
        }, 'app')

        return () => {
            AuthStorage.removeListener('app');
        }
    }, [])

    const handleDrawer = (isOpen : boolean) => {
        setOpen(isOpen);
    };

    let logout = () => {
        storage.removeUserData();
        userData = storage.getUserData()
        setIsLoggedIn(false);
    }


    return (
        <Router>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed"
                        sx={{
                            width: { sm: `calc(100% - ${open ? drawerWidth : 0}px)` },
                            ml: { sm: `${open ? drawerWidth : 0}px` },
                        }}
                    >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => handleDrawer(!open)}
                            edge="start"
                            sx={{ mr: 2}}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={"title"} sx={{flexGrow: 1}}>Recette</Typography>
                        {isLoggedIn &&
                        <Button color="inherit" onClick={logout}>Déconnexion</Button>
                        }
                    </Toolbar>
                </AppBar>
                <SideBar state={open} width={drawerWidth} isLoggedIn={isLoggedIn} setOpen={handleDrawer}/>
                <Main open={open}>
                    <ErrorBoundary>
                        <RouterSwitch>
                            <Route exact path="/auth" component={AuthCard}/>
                            {!isLoggedIn && <Redirect to="/auth" />}

                            <Route exact path="/recipes" component={RecipeListCard}/>
                            <Route path="/recipes/:id(\d+)/view" component={RecipeViewCard}/>
                            <Route path="/recipes/:id(\d+)/edit" component={RecipeEditCard}/>
                            <Route path="/recipes/new" component={RecipeEditCard}/>
                            <Route path="/ingredients/:id(\d+)/view" component={IngredientViewCard}/>
                            <Route exact path="/ingredients" component={IngredientListCard}/>

                            <Route exact path="/units" component={UnitListCard}/>
                            <Route path="/units/:id(\d+)/edit" component={UnitViewCard}/>

                            <Route exact path="/categories" component={CategoryListCard}/>

                            <Route exact path="/plannings" component={PlanningListCard}/>

                            <Route path="/wip" component={WipCard}/>
                            <Route path="/" component={RecipeListCard}/>
                        </RouterSwitch>
                    </ErrorBoundary>
                </Main>
            </Box>
        </Router>
    );
}

export default App;
