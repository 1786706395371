import React from "react";
import UnitsRepository, {Unit} from "../../Repository/Unit";
import {RouteComponentProps} from "react-router-dom";

//to rework on Edit card
export default function UnitViewCard(props: RouteComponentProps<{id: string}>) {
    const id  = parseInt(props.match.params.id);
    const [unit, setUnit] = React.useState<Unit>()

    React.useEffect(()=>{
        UnitsRepository.getRepository()
            .findOne(id)
            .then((result) => {
                setUnit(result);
            })
        ;
    }, [id])

    if(typeof unit === 'undefined'){
        return (
            <div id={"UnitListCard"}>
                Loading
            </div>
        );
    }

    return (
      <div id={"UnitListCard"}>
          Doesn't exist
      </div>
    );
}
