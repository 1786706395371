import React from "react";
import {Card} from "@mui/material";
import {Ingredient} from "../../Repository/Ingredient";
import IngredientSearchCard from "../Ingredient/IngredientSearchCard";

export default function WipCard() {

    let [ingredientListChoose, setIngredientListChoose] = React.useState<Ingredient[]>([]);

    const addList = (ingredient: Ingredient) => {
        setIngredientListChoose([...ingredientListChoose, ingredient])
    }

    return (
        <Card sx={{p:'20px'}}>
            <IngredientSearchCard addIngredient={addList}/>
            <ul>
                {ingredientListChoose.map((ingredient) => {
                    return (
                        <li key={ingredient.id}>{ingredient.name}</li>
                    )
                })}
            </ul>
        </Card>
    );
}