import React, {useEffect} from "react";
import {
  Box, CardContent, Stack, TextareaAutosize, TextField, Typography
} from "@mui/material";
import {IngredientQty, Instruction, Recipe, RecipeQty} from "../../../Repository/Recipe";
import InstructionIngredientsElement from "./InstructionIngredientsElement";
import InstructionRecipesElement from "./InstructionRecipesElement";
import IngredientSearchCard from "../../Ingredient/IngredientSearchCard";
import {Ingredient} from "../../../Repository/Ingredient";
import RecipeSearchCard from "./RecetteSearchCard";

export default function InstructionElement(props: { instruction: Instruction, updateInstruction: (instruction: Instruction) => void }) {

  let ingredients = props.instruction.ingredients;
  let recipes = props.instruction.recipes;

  useEffect(() => {
    setDescription(props.instruction.description);
    setTitle(props.instruction.name)
  }, [props])

  let [description, setDescription] = React.useState<string>(props.instruction.description);
  let [title, setTitle] = React.useState<string>(props.instruction.name);

  const updateInstruction = () => {
    props.updateInstruction({
      id: props.instruction.id,
      name: title,
      order: props.instruction.order,
      created: props.instruction.created,
      modified: props.instruction.modified,
      description: description,
      ingredients,
      recipes,
    } as Instruction)
  }

  const addIngredientToInstruction = (ingredient: Ingredient) => {
    let found = false;
    let ingredientList = ingredients.map(
      (ingredientQty) => {
        if (ingredientQty.ingredientId === ingredient.id) {
          found = true
          ingredientQty.quantity += ingredient.defaultQuantity;
        }
        return ingredientQty
      }
    )
    if (!found) {
      ingredientList.push(
        {
          id: null,
          ingredientId: ingredient.id,
          unitId: ingredient.unit.id,
          quantity: ingredient.defaultQuantity,
          ingredientName: ingredient.name,
          ingredientValPor: null,
          unitAbbreviation: ingredient.unit.abbreviation,
          ingredientValObject: { quantity: ingredient.defaultQuantity, unitId: ingredient.unit.id, value: ingredient.valuation },
          unitName: ingredient.unit.name,
        } as IngredientQty
      )
    }
    ingredients = ingredientList;
    updateInstruction();
  }
  const addRecipeToInstruction = (recipe: Recipe) => {
    let found = false;

    let recipeList = recipes.map(
      (recipeQty) => {
        if (recipeQty.recipeId === recipe.id) {
          found = true
          recipeQty.quantity += 1;
        }
        return recipeQty
      }
    )

    if (!found) {
      recipeList.push(
        {
          id: null,
          recipeId: recipe.id,
          quantity: 1,
          recipeName: recipe.name,
          recipeValPor: recipe.valuationPortion
        } as RecipeQty
      )
    }
    recipes = recipeList;
    updateInstruction();
  }

  return (
    <CardContent sx={{display: 'flex', flexWrap: 'wrap'}}>
      <Box sx={{flex: 3}}>
        <InstructionIngredientsElement updateInstructionIng={(listIng) => {
          ingredients = listIng;
          updateInstruction()
        }} ingredients={ingredients}/>
        <InstructionRecipesElement updateInstructionRec={(listRecipe) => {
          recipes = listRecipe;
          updateInstruction()
        }} recipes={recipes}/>
        <Stack direction="row">
          <IngredientSearchCard addIngredient={(ingredient) => addIngredientToInstruction(ingredient)}/>
          <RecipeSearchCard addRecipe={(recipe) => addRecipeToInstruction(recipe)}/>
        </Stack>

      </Box>
      <Box sx={{flex: 4, ml: '10px'}}>
        <TextField label="Titre de l'étape" value={title} size={"small"} onChange={(e)=>setTitle(e.target.value)} onBlur={updateInstruction}/>
        <Typography variant="h6" gutterBottom component="div">
          Description
        </Typography>
        <TextareaAutosize
          aria-label="Description"
          minRows={7}
          placeholder="Description"
          value={description}
          onChange={(event: any) => setDescription(event.target.value)}
          onBlur={updateInstruction}
          style={{width: '100%'}}
        />
      </Box>
    </CardContent>
  );
}
