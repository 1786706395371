import HttpClientHelper from "../Common/HttpClientHelper";
import Repository from "./Repository";
import {UnitInput} from "./Unit";

export class SimpleRecipe {
    constructor(
        public id: number,
        public name: string,
        public description: string,
        public categoryId: number,
        public unitId: number,
        public portion: number|null,
        public valuation: number|null,
        public duration: number|null,
        public temperature: number|null,
    ) {
    }

    getValuationByPortion(): number|null {
        if(this.valuation === null || this.portion === null ){
            return null;
        }

        return Math.round(this.valuation/this.portion * 1000) /1000;
    }

    getInformation() {
        let content: string[] = [];
        if (this.duration !== null && this.duration> 0) {
            content.push('Duration : ' + this.duration + ' mins')
        }

        if (this.temperature !== null && this.temperature> 0) {
            content.push('Température : ' + this.duration + '°C')
        }

        return content.join(' - ');
    }
}


interface recipeSearch {
    category:{
        ids: string[]|null
    },
    ingredient:{
        ids: number[]|null
        seasons: string[]|null
    },
    recipe:{
        valorization_min : string|null,
        valorization_max : string|null,
        name : string|null,
    }
}


export interface RecipeInput extends RecipeLimited{
    categoryId: number,
    duration : number,
    modified : string,
    created : string,
    description : string,
    portion: number,

    valuation: number,
    valuationPortion: number,
    temperature: number,
    unit: UnitInput,
    instructions: any[],
    quantity: {
        recipes: any[],
        ingredients: any[]
    }
}

export interface RecipeLimited {
    id : number,
    __state : boolean,
    __stateInformation: string,
    name : string,
}

export class Recipe {
    constructor(
        public id : number|null = null,
        public __state : boolean,
        public categoryId: number|null,
        public duration : number|null,
        public name : string,
        public modified : string|null,
        public created : string|null,
        public description : string|null,
        public portion: number = 0,

        public valuation: number = 0,
        public valuationPortion: number = 0,
        public temperature: number|null,
        public unit: UnitInput|null,
        public instructions: Instruction[] = [],
        public quantity: {
            recipes: any[],
            ingredients: {
                ingredientId: number,
                ingredientName: string,
                ingredientVal: { quantity: number, unitId: number, value: number }
                quantity: number
                unitAbbreviation: string
                unitId: number
                unitName: string
            }[]
        }
    ) {
    }
}

export interface Instruction{
    id : number|null,
    name : string,
    order: number,
    created : string,
    modified : string,
    description : string,
    ingredients: IngredientQty[],
    recipes: RecipeQty[],
}
export interface IngredientQty{
    id : number|null,
    ingredientId : number,
    unitId : number,
    quantity: number,

    ingredientName: string,
    ingredientValPor: number|null,
    ingredientValObject: { quantity: number, unitId: number, value: number }

    unitAbbreviation: string,
    unitName: string,
}

export interface RecipeQty{
    id : number|null,
    recipeId : number,
    quantity: number,

    recipeName: string,
    recipeValPor: number,
}


export default class RecipeRepository extends Repository<RecipeInput, Recipe, any> {
    getEntityUrl: string = 'recipes';

    private static instance: RecipeRepository;
    private constructor() {
        super();
    }
    static getRepository(): RecipeRepository {

        if (!RecipeRepository.instance) {
            RecipeRepository.instance = new RecipeRepository();
        }

        return RecipeRepository.instance;
    }


    dataToEntity(data: RecipeInput) {
        return new Recipe(
            data.id,
            data.__state,
            data.categoryId,
            data.duration,
            data.name,
            data.modified,
            data.created,
            data.description,
            data.portion,
            data.valuation,
            data.valuationPortion,
            data.temperature,
            data.unit,
            data.instructions,
            data.quantity,
        )
    }

    entityToData(recipe: Recipe): any {
        return {
            name : recipe.name,
            categoryId: recipe.categoryId,
            duration : recipe.duration,
            description : recipe.description,
            portion: recipe.portion,

            valuation: recipe.valuation,
            valuationPortion: recipe.valuationPortion,
            temperature: recipe.temperature,
            unitId: recipe?.unit?.id || null,
            instructions: recipe.instructions.map(instruction => {
                return {
                    id: instruction.id,
                    name: instruction.name || '',
                    description: instruction.description,
                    recipes: instruction.recipes.map(recipeQty => ({id:recipeQty.id, quantity: recipeQty.quantity, elementId: recipeQty.recipeId})),
                    ingredients: instruction.ingredients.map(ingredientQty => ({id:ingredientQty.id, quantity: ingredientQty.quantity, elementId: ingredientQty.ingredientId, unitId: ingredientQty.unitId})),
                }
            })
        }
    }

    searchLegacy(catsId: string[], ingredientIds: number[], ingSeason: string|null, recipeName: string|null, valMin: string | null, valMax: string | null): Promise<SimpleRecipe[]> {
        let dataSearch:recipeSearch = {
            category:{
                ids:null
            },
            ingredient: {
                ids:null,
                seasons: null
            },
            recipe:{
                "valorization_min" : valMin,
                "valorization_max" : valMax,
                "name" : recipeName,
            }
        };
        if(typeof catsId === 'object' && catsId.length>0){
            dataSearch.category.ids = catsId
        }
        if(typeof ingredientIds === 'object' && ingredientIds.length>0){
            dataSearch.ingredient.ids = ingredientIds;
        }
        if(typeof ingSeason === 'string' && ingSeason.length>0){
            dataSearch.ingredient.seasons = [ingSeason];
        }

        return HttpClientHelper.callWithAuth('POST', '/recipes/search', dataSearch)
            .then((data: any[])=> {
                console.log(data);
                return data.map((line:any)=> new SimpleRecipe(
                    line.id,
                    line.name,
                    line.description,
                    line.categoryId,
                    line.unit.id,
                    line.portion,
                    line.valuation,
                    line.duration,
                    line.temperature,
                ) )
            })
            ;
    }

    update(recipe: Recipe): Promise<any> {
        let uri = '/recipes';
        let method = 'POST';
        if (recipe.id !== null) {
            uri+= '/'+recipe.id;
            method = 'PATCH';
        }

        return HttpClientHelper.callWithAuth(method, uri, this.entityToData(recipe))
          .then((data: any[])=> {
            console.log(data);
          })
          ;
    }
}
