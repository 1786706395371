import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import UnitsRepository, {Unit, UnitsById} from "../../Repository/Unit";
import IngredientCategoriesRepository, {
    IngredientCategoriesById,
    IngredientCategory
} from "../../Repository/IngredientCategory";
import './Ingredient.css'
import TableComponent, {TableComponentContent} from "../../Components/TableComponent";
import IngredientRepository from "../../Repository/Ingredient";
import AddIcon from "@mui/icons-material/Add";
import {useHistory} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

export default function IngredientListCard() {

    const [list, setList] = React.useState<TableComponentContent[]>([]);
    let lastSearch = React.useRef<string>();
    let unitsById = React.useRef<UnitsById>({});
    let ingredientCategoriesById = React.useRef<IngredientCategoriesById>({});

    const history = useHistory();

    React.useEffect(()=>{
        UnitsRepository.getRepository().getUnitById().then(
            (data)=> unitsById.current = data
        )
        IngredientCategoriesRepository.find().then((ingCats)=>{
            let tmp: any = {};
            ingCats.map((ingCat)=>{
                return tmp[ingCat.id] = ingCat;
            })
            ingredientCategoriesById.current = tmp;
        })

    }, []) // <-- empty dependency array

    const search = function (ingName: string|null = null, ingCatId: number|null = null, unitId: number|null = null, valMin: number|null = null, valMax: number|null = null, createdDate: string|null = null) {

        const newSearch = JSON.stringify({ingName, ingCatId, unitId, valMin, valMax, createdDate});
        if (newSearch === lastSearch.current) {
            return;
        }
        lastSearch.current = newSearch;

        IngredientRepository.search(ingName, ingCatId, unitId, valMin, valMax, createdDate).then(
            (data) => {
                return data.map((ingredient):TableComponentContent => {
                    return {
                        key: ingredient.id,
                        data: [
                            ingredient.name,
                            ingredient.unit.id>0 ? unitsById.current[ingredient.unit.id].name : '',
                            ingredient.valuation,
                            ingredient.category !== null && ingredient.category.id >0 ? ingredientCategoriesById.current[ingredient.category.id].name : '',
                        ]
                    }
                })
            }
        ).then((tableContent)=>{
            setList(tableContent);
        })
    }

    const columns = [
        'Nom',
        'Unité',
        'Valorisation',
        'Catégorie'
    ];


    const handleActions = function (action: string, key: string|number): void {
        if (action === 'edit') {
            alert('not yet');
        }else if (action === 'view') {
            history.push('/ingredients/'+ key + '/view');
        }else if (action === 'plan') {
            alert('not yet');
            //history.push('/recipes/'+ key + '/edit');
        }else if (action === 'delete') {
            alert('not yet delete');
            //history.push('/recipes/'+ key + '/edit');
        }
    }


    return (
        <div>
            <IngredientSearchCard search={search}/>
            <Card elevation={6}>
                <CardHeader title={'Liste des ingrédients'} action={ <Button variant="contained" color={"success"} endIcon={<AddIcon />}>Ajouter</Button>}/>
                <CardContent>
                    <TableComponent columns={columns} actions={"all"} actionsHandler={handleActions} content={list}/>
                </CardContent>
            </Card>
        </div>
    )
}

function IngredientSearchCard(props: { search: (ingName: string|null, ingCatId: number|null, unitId: number|null, valMin: number|null, valMax: number|null, createdDate: string|null) => void } ) {

    // search information
    const [checked, setChecked] = React.useState<boolean>(false);
    const [unitId, setUnitId] = React.useState<number>();
    const [categoryId, setCategoryId] = React.useState<number>();
    const [ingredientName, setIngredientName] = React.useState<string>();
    const [valMin, setValMin] = React.useState<number>();
    const [valMax, setValMax] = React.useState<number>();

    //motor
    const [listUnit, setListUnit] = React.useState<Unit[]>([]);
    const [listCategory, setListCategory] = React.useState<IngredientCategory[]>([]);


    React.useEffect(()=>{
        UnitsRepository.getRepository().find().then((list) => setListUnit(list))
        IngredientCategoriesRepository.find().then((list) => setListCategory(list))

    }, []) // <-- empty dependency array

    const handleChangeIngredientName =  (event: any) => {
        setIngredientName(event.target.value as string);
    };
    const handleChangeValMin =  (event: any) : void => {
        setValMin(parseInt(event.target.value as string, 10))
    };
    const handleChangeValMax =  (event: any) => {
        setValMax(parseInt(event.target.value as string, 10))
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Enter') {
            return;
        }
        handleBlur();
    }
    const  handleBlur = () => {
        let createdDate = null;
        if (checked) {
            const d = new Date();
            d.setDate(d.getDate()-7)
            createdDate = d.toISOString().substr(0,10);
        }
        props.search(
            typeof ingredientName !== 'undefined'? ingredientName : null,
            typeof categoryId !== 'undefined' ? categoryId : null,
            typeof unitId !== 'undefined' ? unitId : null,
            typeof valMin !== 'undefined' ? valMin : null,
            typeof valMax !== 'undefined' ? valMax : null,
            typeof createdDate !== 'undefined' ? createdDate : null
        )
    }


    return (
        <Card>
            <CardHeader title={'Recherche'}/>
            <CardContent id="motor-ingredient-cardContent" sx={{ display: 'flex', flexWrap: 'wrap'}}>
                <FormControlLabel control={<Checkbox checked={checked} onChange={(event: any)=> setChecked(event.target.checked)} onKeyDown={handleKeyDown} onBlur={handleBlur} />} label="Créés récemment" />
                <Select
                    value={unitId}
                    size={"small"}
                    displayEmpty
                    onChange={(e) => setUnitId(parseInt(e.target.value as string, 10))}
                    onKeyDown={handleKeyDown} onBlur={handleBlur}
                >
                    <MenuItem value={undefined}>Toutes les unités</MenuItem>
                    {listUnit.map((unit, index) => {
                        return <MenuItem key={index} value={unit.id}>{unit.name}</MenuItem>
                    })}
                </Select>
                <Select
                    value={categoryId}
                    size={"small"}
                    displayEmpty
                    onChange={(e) => setCategoryId(parseInt(e.target.value as string, 10))}
                    onKeyDown={handleKeyDown} onBlur={handleBlur}
                >
                    <MenuItem value={undefined}>Toutes les catégories</MenuItem>
                    {listCategory.map((element, index) => {
                        return <MenuItem key={index} value={element.id}>{element.name}</MenuItem>
                    })}
                </Select>
                <TextField size={"small"} onKeyDown={handleKeyDown} onBlur={handleBlur} value={ingredientName} onChange={handleChangeIngredientName} label="Nom de l'ingrédient" />
                <FormGroup row={true}>
                    <TextField size={"small"} onKeyDown={handleKeyDown} onBlur={handleBlur} value={valMin} onChange={handleChangeValMin} label="Valorisation min" type="number" />
                    <TextField size={"small"} onKeyDown={handleKeyDown} onBlur={handleBlur} value={valMax} onChange={handleChangeValMax} label="Valorisation max" type="number" />
                </FormGroup>
                <Box sx={{flexGrow: "12 !important" as any}}/>
                <div>
                    <Button variant="contained" size={"small"} color="primary" onClick={handleBlur}>
                        <SearchIcon fontSize="inherit" />
                    </Button>
                </div>
            </CardContent>
        </Card>

    );
}
