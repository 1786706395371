import React from "react";
import {
  Box, Button, Input,
  Paper, Table,
  TableBody, TableCell,
  TableContainer, TableHead,
  TableRow
} from "@mui/material";

import {RecipeQty} from "../../../Repository/Recipe";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function InstructionRecipesElement(props: { recipes: RecipeQty[], updateInstructionRec: (listRec: RecipeQty[]) => void }) {
  const removeInstruction = (instructionRecipe: RecipeQty, indexToDelete: number) => {
    delete props.recipes[indexToDelete];
    updateToParent()
  }

  const updateRecQty = (indexToUpdate: number, qty: number) => {
    if (typeof props.recipes[indexToUpdate] === 'undefined') {
      return;
    }
    props.recipes[indexToUpdate].quantity = qty;
    updateToParent()
  }

  const updateToParent = () => {
    props.updateInstructionRec(props.recipes.flat().filter((data:any|null) => data!==null))
  }

  if (props.recipes.length === 0) {
    return (
      <Box/>
    )
  }

  return (
    <Box sx={{flex: 4, mb: '10px'}}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Recipe</TableCell>
              <TableCell>Qte</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>

            {props.recipes.map((qteInfo, index: number) => (
              <TableRow key={index}>
                <TableCell>{qteInfo.recipeName}</TableCell>
                <TableCell>
                  <Input
                    type={"number"}
                    value={qteInfo.quantity}
                    onChange={(event: any) => updateRecQty(index, Number(event.target.value))}
                  />
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="error" size={"small"}
                          onClick={() => removeInstruction(qteInfo, index)}>
                    <DeleteForeverIcon fontSize="inherit"/>
                  </Button>
                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
    </Box>

  );
}
