import HttpClientHelper from "../Common/HttpClientHelper";
import Repository from "./Repository";

export interface UnitsById {
    [key: number]: Unit;
}

export class Unit
{
    constructor(
        public id: number,
        public name: string,
        public shortName: string,
        public abbreviation: string,
        public referenceValue: number,
        public typeId: number,
        public created: string,
        public modified: string,
        public __state: any,
        public __stateInformation: string|null
    ) {
    }

    getType() {
        switch (this.typeId) {
            case 1:
                return 'Time';
            case 2:
                return 'Quantity';
            case 3:
                return 'Portion';
        }
        return 'None';
    }
}

export interface UnitInput extends UnitLimited{
    shortName: string,
    typeId: number,

    //to defined
    referenceValue: any,
    created: string,
    modified: string,
}


export interface UnitLimited {
    id: number,
    __state: boolean,
    __stateInformation: string,
    name: string,
    abbreviation: string,
}


export default class UnitsRepository extends Repository<UnitInput, Unit, any>{

    private static instance: UnitsRepository;
    private constructor() {
        super();
    }
    static getRepository(): UnitsRepository {

        if (!UnitsRepository.instance) {
            UnitsRepository.instance = new UnitsRepository();
        }

        return UnitsRepository.instance;
    }

    dataToEntity(data: UnitInput): any {
        return new Unit(
            data.id,
            data.name,
            data.shortName,
            data.abbreviation,
            data.referenceValue,
            data.typeId,
            data.created,
            data.modified,
            data.__state,
            data.__stateInformation
        );
    }

    readonly getEntityUrl: string = 'units';

    getUnitById(): Promise<UnitsById>{
        return UnitsRepository.getRepository().find().then(
            (units) => {
                const result: UnitsById = {};
                units.map((unit)=> {
                    result[unit.id] = unit
                    return unit;
                })
                return result;
            }
        )
    }
    static getQuantityUnit(){
        return UnitsRepository.getRepository().find().then(
          (units) => units.filter((unit)=> unit.typeId === 2)
        )
    }

    static getPortionUnit(){
        return UnitsRepository.getRepository().find().then(
          (units) => units.filter((unit)=> unit.typeId === 3)
        )
    }
    static getTimeUnit(){
        return UnitsRepository.getRepository().find().then(
          (units) => units.filter((unit)=> unit.typeId === 1)
        )
    }

    static update(id: number, unit: Unit) {

        const data: any = {
            name : unit.name,
            abbreviation: unit.shortName,
            shortName: unit.shortName,
            typeId: unit.typeId
        }
        if (unit.referenceValue > 0) {
            data['referenceValue'] = unit.referenceValue
        }

        if (id > 0) {
            return HttpClientHelper.callWithAuth('PATCH', '/units/'+id, data)
        } else {
            return HttpClientHelper.callWithAuth('POST', '/units', data)
        }
    }

    static getTypeUnit() {
        return [
            {'id':'1', 'name': 'Time'},
            {'id':'2', 'name': 'Quantity'},
            {'id':'3', 'name': 'Portion'},
        ];
    }
}
