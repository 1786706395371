import React from "react";
import {
    Autocomplete, Box, Button,
    Card, CardContent, CardHeader,
    Chip, FormControl, InputLabel,
    MenuItem, Select, SelectChangeEvent, TextField
} from "@mui/material";

// icons
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import Grid from '@mui/material/Grid';

import CategoriesRepository, {CategoryFlattenIdsName} from "../../Repository/Category";
import IngredientRepository, {Ingredient} from "../../Repository/Ingredient";

export interface RecipeEngineData {
    catsId: string[],
    ingredientIds: number[],
    ingSeason: string|null,
    recipeName: string|null,
    valMin: string|null,
    valMax: string|null
}


function RecipeSearchCard(props: {lastSearch: RecipeEngineData|null, reset: () => void ,  search: (info: RecipeEngineData, force: boolean) => void } ) {
    const [listCat, setListCat] = React.useState<CategoryFlattenIdsName[]>([]);
    const [listIng, setListIng] = React.useState<Ingredient[]>([]);

    // search engine => should create one Object
    const [category, setCategory] = React.useState('');
    const [month, setMonth] = React.useState<string>(props.lastSearch?.ingSeason || '');
    const [recipeName, setRecipeName] = React.useState<string>(props.lastSearch?.recipeName || '');
    const [valMin, setValMin] = React.useState<string>(props.lastSearch?.valMin || '');
    const [valMax, setValMax] = React.useState<string>(props.lastSearch?.valMax || '');
    const [ingredientIds, setIngredientIds] = React.useState<Ingredient[]>([]);

    const months =[
        {'id':'01', 'name':'Janvier'},
        {'id':'02', 'name':'Février'},
        {'id':'03', 'name':'Mars'},
        {'id':'04', 'name':'Avril'},
        {'id':'05', 'name':'Mai'},
        {'id':'06', 'name':'Juin'},
        {'id':'07', 'name':'Juillet'},
        {'id':'08', 'name':'Aout'},
        {'id':'09', 'name':'Septembre'},
        {'id':'10', 'name':'Octobre'},
        {'id':'11', 'name':'Novembre'},
        {'id':'12', 'name':'Décembre'},
    ]

    React.useEffect(()=>{
        //todo don't call at any change of lastsearch
        const catRepo = new CategoriesRepository();
        catRepo.getCatsTreeAsList().then((result) => {
            setListCat(result)
            const lastSearchCat  = props.lastSearch?.catsId || [];
            if (lastSearchCat.length > 0){
                setCategory(lastSearchCat.join(','));
            }
        })
        IngredientRepository.getRepository().find()
            .then((result) => {
                setListIng(result)
                const lastSearchIng  = props.lastSearch?.ingredientIds || [];
                if (lastSearchIng.length > 0){
                    setIngredientIds(result.filter(
                            ing => lastSearchIng.indexOf(ing.id) === 0
                    ));
                }
            })
    }, [props.lastSearch]) // <-- empty dependency array


    const handleChangeCategory = (event: SelectChangeEvent) => {
        setCategory(event.target.value as string);
    };
    const handleChangeMonth = (event: SelectChangeEvent) => {
        setMonth(event.target.value as string);
    };

    const handleChangeRecipeName =  (event: any) => {
        setRecipeName(event.target.value as string);
    };
    const handleChangeValMin =  (event: any) : void => {
        setValMin(event.target.value as string);
    };
    const handleChangeValMax =  (event: any) => {
        setValMax(event.target.value as string);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Enter') {
            return;
        }
        handleBlur();
    }
    const  handleBlur = () => {
        search(false)
    }

    const clean = () => {
        setCategory('');
        setMonth('');
        setRecipeName('');
        setValMin('');
        setValMax('');
        setIngredientIds([]);
        props.reset()
    }


    const search =  (force: boolean) => {
        const info: RecipeEngineData = {
            catsId: category === '' ?[] : category.split(','),
            ingredientIds: ingredientIds.map((ing)=> ing.id),
            ingSeason: month,
            recipeName: recipeName ,
            valMin: valMin !== '' ? valMin : null,
            valMax: valMax !== '' ? valMax : null
        };

        props.search(info, force)
    }
    /*
    #motor-recipe-cardContent > div {

    }
    */

    return (
        <Card sx={{mb:'30px'}} elevation={6}>
            <CardHeader title={'Recherche'}/>

            <CardContent id="motor-recipe-cardContent" sx={{flexGrow: 1}}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth={true}>
                            <InputLabel shrink id="recipe-engine-category">Catégorie </InputLabel>
                            <Select
                              labelId="recipe-engine-category"
                              value={category}
                              size={"small"}
                              displayEmpty
                              onChange={handleChangeCategory}
                              onKeyDown={handleKeyDown} onBlur={handleBlur}
                            >
                                <MenuItem value={""}>Toutes les catégories</MenuItem>
                                {listCat.map((catData, index) => {
                                    return <MenuItem key={index} value={catData.ids.join(',')}>{catData.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth={true}>
                            <Autocomplete
                              multiple
                              options={listIng}
                              value={ingredientIds}
                              size={"small"}
                              onChange={(e: any, data2: Ingredient[]) => setIngredientIds(data2)}
                              onKeyDown={handleKeyDown} onBlur={handleBlur}
                              getOptionLabel={(ingredient: Ingredient) => ingredient.name}
                              renderTags={(ings: Ingredient[], getTagProps) =>
                                ings.map((ing: Ingredient, index: number) => (
                                  <Chip key={index} size={"small"} variant="outlined" label={ing.name} /*{...getTagProps({ index })}*/ />
                                ))
                              }
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              renderInput={(params: any) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Ingredients"
                                  placeholder="Ingredients"
                                />
                              )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth={true}>
                            <TextField size={"small"} onKeyDown={handleKeyDown} onBlur={handleBlur} value={recipeName} onChange={handleChangeRecipeName} label="Nom de la recette" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div>
                            <TextField sx={{width: '50%'}} size={"small"} onKeyDown={handleKeyDown} onBlur={handleBlur} value={valMin} onChange={handleChangeValMin} label="Valorisation min" type="number" />
                            <TextField sx={{width: '50%'}} size={"small"} onKeyDown={handleKeyDown} onBlur={handleBlur} value={valMax} onChange={handleChangeValMax} label="Valorisation max" type="number" />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <FormControl fullWidth={true}>
                            <InputLabel shrink id="recipe-engine-month">
                                Liste des mois
                            </InputLabel>
                            <Select
                              labelId="recipe-engine-month"
                              size={"small"}
                              value={month}
                              displayEmpty
                              onChange={handleChangeMonth}
                              onKeyDown={handleKeyDown}
                              onBlur={handleBlur}
                            >
                                <MenuItem value={""}>Toutes les mois</MenuItem>
                                {months.map((monthData, index) => {
                                    return <MenuItem key={index} value={monthData.id}>{monthData.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ marginLeft: "auto", display: 'flex', justifyContent: 'right'  }}>
                            <Button variant="contained" color="primary" onClick={() => search(true)}>
                                <SearchIcon fontSize="inherit" />
                            </Button>
                            <Button  variant="contained" color="secondary" onClick={clean} >
                                <ReplayIcon fontSize="inherit" />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
}


export default RecipeSearchCard;
