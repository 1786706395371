import React from "react";
import {Autocomplete, Box, FormControl, IconButton, TextField} from "@mui/material";
import IngredientRepository, {Ingredient} from "../../Repository/Ingredient";
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function IngredientSearchCard(props: {addIngredient: (ing: Ingredient) => void } ) {

    let [ingredientList, setIngredientList] = React.useState<Ingredient[]>([]);
    let [ingredientChoose, setIngredientChoose] = React.useState<Ingredient|null>(null);

    React.useEffect(() => {
        IngredientRepository.getRepository()
            .find()
            .then((list) => setIngredientList(list))
    }, []) // <-- empty dependency array

    return (
        <Box sx={{p: '15px'}}>
            <FormControl>
                <Autocomplete
                    size={"small"}
                    sx={{ width: 200 }}
                    value={ingredientChoose}
                    options={ingredientList}
                    getOptionLabel={(option) => option.name}
                    clearOnEscape
                    onChange={(event, newValue) => {
                        setIngredientChoose(newValue as Ingredient|null)
                    }}
                    renderOption={(props: any , option: Ingredient) => {
                        props.key = option.id
                        return (<li {...props}>{option.name}</li>)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ingredients"
                        />
                    )}
                />
            </FormControl>
            <IconButton aria-label="delete" disabled={ingredientChoose === null}
                onClick={()=>{
                    if(ingredientChoose !== null){
                        props.addIngredient(ingredientChoose)
                        setIngredientChoose(null)
                    }
                }
            }>
                <AddCircleIcon color={ingredientChoose === null ? 'disabled' : "success"}/>
            </IconButton>
        </Box>
    );
}
