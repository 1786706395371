import React from "react";
import {
    Box, Button, Input, MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {IngredientQty} from "../../../Repository/Recipe";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UnitsRepository, {Unit} from "../../../Repository/Unit";

export default function InstructionIngredientsElement(props: {ingredients: IngredientQty[], updateInstructionIng: (listIng: IngredientQty[]) => void } ) {

    const [units, setUnits] = React.useState<Unit[]>([]);

    React.useEffect(()=>{
        UnitsRepository.getQuantityUnit()
        .then(
            (units)=> setUnits(units)
        )
    }, []) // <-- empty dependency array

    const updateToParent = () => {
        props.updateInstructionIng(props.ingredients.filter((data:any|null) => data!==null))
    }

    const updateIngQty = (indexToUpdate: number, qty: number) => {
        if (typeof props.ingredients[indexToUpdate] === 'undefined'){
            return;
        }
        props.ingredients[indexToUpdate].quantity = qty;
        updateToParent()
    }

    const updateIngQtyUnity = (indexToUpdate: number, unitId: number) => {
        if (typeof props.ingredients[indexToUpdate] === 'undefined'){
            return;
        }
        const unit = units.find(unit => unit.id === unitId);

        props.ingredients[indexToUpdate].unitId = unitId;
        props.ingredients[indexToUpdate].unitName = unit?.name || '';
        props.ingredients[indexToUpdate].unitAbbreviation = unit?.abbreviation || '';

        updateToParent()
    }

    const removeInstruction = (instructionIngredient: IngredientQty, indexToDelete: number) => {
        delete props.ingredients[indexToDelete];
        updateToParent()
    }
    if (props.ingredients.length === 0) {
        return (
            <Box/>
        )
    }


    if (units.length === 0) {
        return (
            <div>
                loading Ingredient
            </div>
        );
    }

    return (
        <Box sx={{flex: 4, mb: '10px'}}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ingredient</TableCell>
                            <TableCell>Qte</TableCell>
                            <TableCell>Unité</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.ingredients.map((qteInfo, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{qteInfo.ingredientName}</TableCell>
                                <TableCell>
                                    <Input
                                        type={"number"}
                                        value={qteInfo.quantity}
                                        onChange={(event: any)=> updateIngQty(index, Number(event.target.value))}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={qteInfo.unitId}
                                        onChange={(event: any)=> updateIngQtyUnity(index, event.target.value)}
                                        size={"small"}
                                        variant={"standard"}
                                    >
                                        {units.map((unit, indexMenu)=> (
                                            <MenuItem key={indexMenu} value={unit.id}>{unit.abbreviation}</MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                  <Button variant="contained" color="error" size={"small"}
                                          onClick={() => removeInstruction(qteInfo,index)}>
                                    <DeleteForeverIcon fontSize="inherit"/>
                                  </Button>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    );
}
