import React from "react";
import {
    alpha, Button, ButtonGroup,
    Card, CardContent, CardHeader, Collapse, SvgIcon, SvgIconProps
} from "@mui/material";

import { useSpring, animated } from '@react-spring/web';
import {TreeItem, treeItemClasses, TreeItemProps, TreeView} from "@mui/lab";
import {styled} from "@mui/material/styles";
import {TransitionProps} from "@mui/material/transitions";
import CategoriesRepository, {Category} from "../../Repository/Category";

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import './Category.css';

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

export default function CategoryListCard() {

    const [list, setList] = React.useState<Category[]>([])

    React.useEffect(()=>{
        CategoriesRepository.findTree()
            .then((list) => {
                setList(list);
                console.log(list);
            })

    }, [])

    const handleAction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, action: string, id: number, element: Category|null) => {
        console.log(action, id, element)
    }

    const renderLine = (catName: string, id: number, index: number, children: Category[]) => (
        <div className={'category-line'}>
            <div className={'category-line-content'}>{catName}</div>
            <ButtonGroup disableElevation variant="contained">
                <Button variant="contained" color="inherit" size={"small"} disabled={index === 0} onClick={(event) => handleAction(event,'up', id, children[index-1] || null)}>
                    <ArrowUpwardIcon fontSize="inherit" />
                </Button>
                <Button variant="contained" color="inherit" size={"small"} disabled={index === children.length -1}  onClick={(event) => handleAction(event,'down', id, children[index+1] || null)}>
                    <ArrowDownwardIcon fontSize="inherit" />
                </Button>

                <Button variant="contained" color="success" size={"small"} onClick={(event) => handleAction(event,'add', id, null)}>
                    <AddIcon fontSize="inherit" />
                </Button>

                <Button variant="contained" color="inherit" size={"small"} onClick={(event) => handleAction(event,'edit', id, null)}>
                    <EditIcon fontSize="inherit" />
                </Button>
                <Button variant="contained" color="error"  size={"small"} onClick={(event) => handleAction(event,'delete', id, null)}>
                    <DeleteForeverIcon fontSize="inherit" />
                </Button>
            </ButtonGroup>
        </div>
    )

    const renderTree = (nodes: Category, index: number, children: Category[]) => (
        <StyledTreeItem key={nodes.id} nodeId={nodes.id.toString()} label={renderLine(nodes.name, nodes.id, index, children)} >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node, index) => renderTree(node, index, nodes.children))
                : null}
        </StyledTreeItem>
    );

    return (
      <div id={"CategoryListCard"}>
          <Card elevation={6} sx={{ maxWidth: 600}}>
              <CardHeader title={'Liste des catégories'} action={ <Button variant="contained" color={"success"} endIcon={<AddIcon />}>Ajouter</Button>}/>
              <CardContent>
                  <TreeView
                      aria-label="customized"
                      defaultCollapseIcon={<MinusSquare />}
                      defaultExpandIcon={<PlusSquare />}
                      defaultEndIcon={<CloseSquare />}
                  >
                      {list.map((cat, index) => {
                          return renderTree(cat, index, list)
                      })}
                  </TreeView>
              </CardContent>
          </Card>
      </div>
    );
}
