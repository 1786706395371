import HttpClientHelper from "../Common/HttpClientHelper";
import {Category} from "./Category";
import Repository from "./Repository";
import {UnitLimited} from "./Unit";
import {RecipeLimited} from "./Recipe";


export interface IngredientInput {
    id : number,
    name : string,
    description : string,
    category: any,
    defaultQuantity: number,
    valuation: number,
    recipes: RecipeLimited[]
    seasons: any[],
    temperature: number,
    unit: UnitLimited,
    instructions: string[],

    modified : string,
    created : string,

}

export class Ingredient {
    constructor(
        public id: number,
        public name: string,
        public description: string,
        public category: Category|null,
        public defaultQuantity: number,
        public seasons: string[],
        public unit: UnitLimited,
        public valuation: number,
        public recipes: RecipeLimited[]
    ) {}
}

class IngredientRepository extends Repository<IngredientInput, Ingredient, any> {

    private static instance: IngredientRepository;
    private constructor() {
        super();
    }
    static getRepository(): IngredientRepository {

        if (!IngredientRepository.instance) {
            IngredientRepository.instance = new IngredientRepository();
        }

        return IngredientRepository.instance;
    }

    readonly getEntityUrl: string = 'ingredients';


    dataToEntity(data: IngredientInput): Ingredient {
        return new Ingredient(
            data.id,
            data.name,
            data.description,
            data.category.id === null ? data.category : null,
            data.defaultQuantity,
            data.seasons,
            data.unit,
            data.valuation,
            data.recipes
        );
    }

    static getSeasons() {
        return [
            {'id':'01', 'name': 'Janvier'},
            {'id':'02', 'name': 'Février'},
            {'id':'03', 'name': 'Mars'},
            {'id':'04', 'name': 'Avril'},
            {'id':'05', 'name': 'Mai'},
            {'id':'06', 'name': 'Juin'},
            {'id':'07', 'name': 'Juillet'},
            {'id':'08', 'name': 'Août'},
            {'id':'09', 'name': 'Septembre'},
            {'id':'10', 'name': 'Octobre'},
            {'id':'11', 'name': 'Novembre'},
            {'id':'12', 'name': 'Décembre'},
        ];
    }
    static getSeasonsById() {
        let data : any= {};
        IngredientRepository.getSeasons().map((season) => {
            return data[season.id] = season.name;
        })
        return data;
    }

    static get(id: number): Promise<Ingredient> {
        return HttpClientHelper.callWithAuth('GET','/ingredients/'+id).then((response) => response.json())
            .then((rawIngredient: any) => {
                let category: Category|null = null;
                if(rawIngredient.category.id!== null) {
                    category = new Category(
                        rawIngredient.category.id,
                        rawIngredient.category.name
                    )
                }
                return new Ingredient(
                    rawIngredient.id,
                    rawIngredient.name,
                    rawIngredient.description,
                    category,
                    rawIngredient.defaultQuantity,
                    rawIngredient.seasons,
                    rawIngredient.unit,
                    rawIngredient.valuation,
                    rawIngredient.recipes
                )
            })
            ;
    }
    static search(ingName: string|null = null, ingCatId: number|null = null, unitId: number|null = null, valMin: number|null = null, valMax: number|null = null, createdDate: string|null = null): Promise<Ingredient[]>{
        let dataSearch = {
            name: ingName,
            categoryId: ingCatId,
            unitId: unitId,
            valMin: valMin,
            valMax: valMax,
            created: createdDate,
        };

        return HttpClientHelper.callWithAuth('POST', '/ingredients/search', dataSearch)
            .then((data: any) => {
                console.log(data);
                return data.map((rawIngredient: any)=> {
                    let category: Category|null = null;
                    if(rawIngredient.category.id!== null) {
                        category = new Category(
                            rawIngredient.category.id,
                            rawIngredient.category.name
                        )
                    }
                    return new Ingredient(
                        rawIngredient.id,
                        rawIngredient.name,
                        rawIngredient.description,
                        category,
                        rawIngredient.defaultQuantity,
                        rawIngredient.seasons,
                        rawIngredient.unit,
                        rawIngredient.valuation,
                        rawIngredient.recipes

                    )
                })
            })
            ;
    }

    /*
    static update(id, data){
        if (id > 0) {
            return BackEnd.callWithAuthHttp('PATCH', '/ingredients/'+id, data);
        } else {
            return BackEnd.callWithAuthHttp('POST', '/ingredients', data);
        }
    }*/

    /*
    static find(): Promise<Ingredient[]>{


        return HttpClientHelper.callWithAuth('GET', '/ingredients')
            .then((response) => response.json())
            .then((data: any) => {
                return data.map((rawIngredient: any)=> {
                    let category: Category|null = null;
                    if(rawIngredient.category.id!== null) {
                        category = new Category(
                            rawIngredient.category.id,
                            rawIngredient.category.name
                        )
                    }
                    return new Ingredient(
                        rawIngredient.id,
                        rawIngredient.name,
                        rawIngredient.description,
                        category,
                        rawIngredient.defaultQuantity,
                        rawIngredient.seasons,
                        rawIngredient.unit,
                        rawIngredient.valuation,
                        rawIngredient.recipes

                    )
                })
            })
            ;
    }
     */
    static delete(id: number){
        return HttpClientHelper.callWithAuth('DELETE', '/ingredients/' + id);
    }
}

export default IngredientRepository;
