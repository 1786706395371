import React from "react";
import {Button, Card, CardContent, CardHeader} from "@mui/material";
import {useHistory} from "react-router-dom";

// icons
import AddIcon from '@mui/icons-material/Add';

import CategoriesRepository, {CategoriesById} from "../../Repository/Category";

import TableComponent, {TableComponentContent} from "../../Components/TableComponent";
import RecipeSearchCard, {RecipeEngineData} from "./RecipeSearchCard";
import DataStorage from "../../Common/DataStorage";
import RecipeRepository from "../../Infra/Recipe/Repository";
import UnitsRepository, {UnitsById} from "../../Repository/Unit";


function RecipeListCard() {

  const history = useHistory();

  const columns = [
    'Nom',
    'Catégorie',
    'Portion / Quantité',
    //'Valorisation',
    //'Information'
  ];
  const storageClassName = 'recipe_engine';
  const storage = new DataStorage<any>();

  let lastSearch = React.useRef<RecipeEngineData | null>(storage.getData(storageClassName));
  let categoryById = React.useRef<CategoriesById>({});
  let unitById = React.useRef<UnitsById>({});
  const recipeRepo = RecipeRepository.getRepository();
  const [recipes, setRecipes] = React.useState<TableComponentContent[]>([]);
  const [recipesLabel, setRecipesLabel] = React.useState<{ [key: number]: string }>({});

  React.useEffect(() => {
    CategoriesRepository.getRepository().getCatsWithIdKey().then((result) => {
      categoryById.current = result;
    })
    UnitsRepository.getPortionUnit().then((units) => {
      const result: UnitsById = {};
      units.map((unit) => {
        result[unit.id] = unit
        return unit;
      })
      unitById.current = result;
    })
    if (null !== lastSearch.current) {
      search(lastSearch.current, true)
    }
  }, []) // <-- empty dependency array

  const reset = () => {
    lastSearch.current = null;
    setRecipes([])
  }

  const search = (info: RecipeEngineData, force = false) => {
    const newSearch = JSON.stringify(info);
    if (!force && newSearch === JSON.stringify(lastSearch.current)) {
      console.log(force)
      return;
    }

    lastSearch.current = info;
    storage.setData(storageClassName, info, 3600);

    recipeRepo.searchLegacy(
      info.catsId,
      info.ingredientIds,
      info.ingSeason,
      info.recipeName,
      info.valMin,
      info.valMax
    ).then((list) => {
        let listLabel: { [key: number]: string } = {};
        setRecipes(list.map((recipe) => {
            listLabel[recipe.id as number] = recipe.name;
            const unitId = recipe.unitId || 0;
            let unitName = unitId > 0 && undefined !== typeof unitById.current[unitId] ? unitById.current[unitId].name : '';
            let portionLabel: string = recipe.portion + (unitName !== '' ? ' ' + unitName : '');
            return {
              key: recipe.id as number,
              data: [
                recipe.name,
                null !== recipe.categoryId && recipe.categoryId > 0 ? categoryById.current[recipe.categoryId].name : '',
                portionLabel,
                //recipe.getValuationByPortion(),
                //recipe.getInformation(),
              ]
            }
          })
        )
        setRecipesLabel(listLabel);
      }
    )
  }
  const handleActions = function (action: string, key: string | number | null): void {
    switch (action) {
      case 'edit': {
        history.push('/recipes/' + key + '/edit');
        break;
      }
      case 'view': {
        history.push('/recipes/' + key + '/view');
        break;
      }
      case 'new': {
        history.push('/recipes/new');
        break;
      }
      case 'delete': {
        let message = 'Êtes vous sur de vouloir supprimer la recette : ' + recipesLabel[key as number];
        if(window.confirm(message)) {
          RecipeRepository.delete(key as number)
            .then((response) => {
              console.log(response);
              if (200 === response.status || 204 === response.status) {
                setRecipes(recipes.filter((line) => line.key !== key))
              } else {
                window.alert(response.body.error)
              }
              console.log('data',response.body)
            })
            .catch((data) => {
              console.log('data', data);
            })
          //
        }
        break;
      }
      default: {
        alert('Action non traitée "' + action + '"');
        break;
      }
    }
  }

  return (
    <div id={"RecipeListCard"}>
      <RecipeSearchCard lastSearch={lastSearch.current} search={search} reset={reset}/>
      <Card elevation={6}>
        <CardHeader title={'Liste des recettes'}
                    action={<Button variant="contained" color={"success"} onClick={() => handleActions('new', null)}
                                    endIcon={<AddIcon/>}>Ajouter</Button>}/>
        <CardContent>
          <TableComponent columns={columns} actions={"recipe"} actionsHandler={handleActions} content={recipes}/>
        </CardContent>
      </Card>
    </div>
  );
}

export default RecipeListCard;
