import HttpClientHelper from "../Common/HttpClientHelper";

export interface IngredientCategoriesById {
    [key: number]: IngredientCategory;
}

export class IngredientCategory {
    constructor(
        public id: number,
        public name: string
    ) {
    }
}

class IngredientCategoriesRepository {
    static find(): Promise<IngredientCategory[]> {
        return HttpClientHelper.callWithAuth('GET', '/ingredients/categories')
            .then((catIngListData) =>
                catIngListData.map(
                    (rawCatInfo: any) => new IngredientCategory(rawCatInfo.id, rawCatInfo.name)
                )
            )
    }
}

export default IngredientCategoriesRepository
