import {Alert, Card, CardActions, CardContent, CardHeader, TextField} from "@mui/material";
import './AuthCard.css';
import React from "react";
import AuthStorage, {AuthInformation} from "../Common/AuthStorage";
import HttpClientHelper from "../Common/HttpClientHelper";
import {useHistory} from "react-router-dom";
import {LoadingButton} from "@mui/lab";

//icons
import SendIcon from '@mui/icons-material/Send';


interface stateData {
    username: string,
    password: string,
    errorMessage: string | undefined
}


function AuthCard() {
    const storage = new AuthStorage()
    const history = useHistory();
    let userData = storage.getUserData()

    React.useEffect(()=>{
        //should remove listener
        AuthStorage.addListener((info: AuthInformation|null)=> {
            if (null !== info) {
                AuthStorage.removeListener('auth_card');
                history.push('/recipes');
            }
        }, 'auth_card')
    })


    const [state, setState] = React.useState<stateData>({username: userData?.username || '', password: ''} as stateData);
    const [isLoading, setLoading] = React.useState(false);

    let handleChangeEmail = (event: any) => {
        setState(prevState => ({
            ...prevState,
            username: event.target.value
        }));
    }
    let handleChangePassword = (event: any) => {
        setState(prevState => ({
            ...prevState,
            password: event.target.value
        }));
    }

    let checkAuth = () => {
        if(isLoading){
            return
        }
        setLoading(true)

        HttpClientHelper.call('POST', '/auth/login_check', state)
        .then(response => {
            setState(prevState => ({
                ...prevState,
                errorMessage: undefined
            }));
            return response.json();
        })
        .then( (data: {message?: string, token?: string}) => {
            if (undefined !== data.token) {
                storage.setUserData({username: state.username, jwt: data.token});
            } else {
                setState(prevState => ({
                    ...prevState,
                    errorMessage: data.message
                }));
            }
            setLoading(false)
        })
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Enter') {
            return;
        }
        checkAuth();
    }

    return (
        <Card className="AuthCard" variant="outlined">
            <CardHeader title={'Authentification requise'}/>
            <CardContent>
                <TextField required name="login" label="Identifiant de connexion" value={state.username}
                           onChange={handleChangeEmail} onKeyDown={handleKeyDown}/>
                <TextField required name="password" label="Mot de passe" type="password" value={state.password}
                           onChange={handleChangePassword} onKeyDown={handleKeyDown}/>
                {state.errorMessage && !isLoading &&
                    <Alert severity="error">{state.errorMessage}</Alert>
                }
            </CardContent>
            <CardActions>
                <LoadingButton loading={isLoading} size={'large'} variant={'outlined'} color={'primary'} onClick={checkAuth} endIcon={<SendIcon />}>Connexion</LoadingButton>
            </CardActions>
        </Card>
    )
}

export default AuthCard;
