import React from "react";
import {Autocomplete, Box, FormControl, IconButton, TextField} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RecipeRepository, {Recipe} from "../../../Repository/Recipe";

export default function RecipeSearchCard(props: {addRecipe: (el: Recipe) => void } ) {

    let [recipeList, setRecipeList] = React.useState<Recipe[]>([]);
    let [recipeChoose, setRecipeChoose] = React.useState<Recipe|null>(null);

    React.useEffect(() => {
        RecipeRepository.getRepository()
            .find()
            .then((list) => setRecipeList(list))
    }, []) // <-- empty dependency array

    return (
        <Box sx={{p: '15px'}}>
            <FormControl>
                <Autocomplete
                    size={"small"}
                    sx={{ width: 200 }}
                    value={recipeChoose}
                    options={recipeList}
                    getOptionLabel={(option) => option.name }
                    clearOnEscape
                    onChange={(event, newValue) => {
                      setRecipeChoose(newValue as Recipe|null)
                    }}
                    renderOption={(props: any , option: Recipe) => {
                        props.key = option.id
                        return (<li {...props}>{option.name}</li>)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Recettes"
                        />
                    )}
                />
            </FormControl>
            <IconButton aria-label="delete" disabled={recipeChoose === null}
                onClick={()=>{
                    if(recipeChoose !== null){
                        props.addRecipe(recipeChoose)
                        setRecipeChoose(null)
                    }
                }
            }>
                <AddCircleIcon color={recipeChoose === null ? 'disabled' : "success"}/>
            </IconButton>
        </Box>
    );
}
