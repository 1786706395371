import React from "react";
import moment from 'moment';
import {
    Button, Card, CardContent, CardHeader, IconButton,
    InputAdornment, List, ListItem, ListItemText, TextField
} from "@mui/material";

import DateAdapter from '@mui/lab/AdapterMoment';

import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';

import './Planning.css'
import {DesktopDatePicker, LocalizationProvider} from "@mui/lab";
import PlanningRepository, {Planning, PlanningsByDate, PlanningSearch} from "../../Repository/Planning";



export default function PlaningListCard() {

    const [list, setList] = React.useState<PlanningsByDate[]>([]);
    let lastSearch = React.useRef<PlanningSearch|null>(null);

    React.useEffect(
        ()=> {
            search({
                date: moment(new Date()).format('yyyy-MM-DD'),
                term: 7
            })
        },
        []
    );

    const search = (dataSearch: PlanningSearch): void => {
        if(lastSearch.current !== null || JSON.stringify(lastSearch.current) === JSON.stringify(dataSearch) ) {
            return;
        }
        lastSearch.current = dataSearch;
        PlanningRepository.getRepository()
            .searchByDate(dataSearch)
            .then((data) => setList(data));
    }
    if (typeof list === 'undefined') {
        return (
            <div>
                loading
            </div>
        )
    }
    console.log(list);
    const createTitle = function (planning: Planning): JSX.Element {
        return <React.Fragment>
            {planning.cycle_id === null ? '' : <AccessTimeIcon fontSize="inherit" color={planning.cycle_edited ? "secondary" : "info"} /> }

            {planning.getHour() + ' pour ' + planning.portion + ' convives - {valorisation todo}'}
        </React.Fragment>;

    }

    return (
        <div>
            <PlaningEngine search={search} defaultDate={new Date()} defaultTerm={7} />

            {list.map((data: PlanningsByDate, index) => (
                <Card key={index} sx={{mb:'30px'}} elevation={6}>
                    <CardHeader title={'Date : ' + data.date}/>
                    <CardContent>
                        {data.list.map((planning: Planning, index2) => (
                            <Card key={index2} sx={{mb: '30px'}} elevation={6} className={planning.cycle_id >0 ? 'planning-card-normal' : 'planning-card-' +  (planning.cycle_edited ? 'edited' : 'cycle') }>
                                <CardHeader title={createTitle(planning)}/>
                                <CardContent>
                                    {planning.description}
                                    <List dense={true}>
                                        {planning.recipes.map((recipe)=>(
                                            <ListItem button key={recipe.id}
                                                      secondaryAction={
                                                          <IconButton edge="end" aria-label="delete">
                                                              <VisibilityIcon />
                                                          </IconButton>
                                                      }>
                                                <ListItemText primary={recipe.recipeName} />
                                            </ListItem>
                                        ))}
                                        {planning.ingredients.map((ing)=>(
                                            <ListItem button key={ing.id}
                                                  secondaryAction={
                                                    <IconButton edge="end" aria-label="delete">
                                                      <VisibilityIcon />
                                                    </IconButton>
                                                  }>
                                                <ListItemText primary={ing.ingredientName || 'nope'} />
                                            </ListItem>
                                        ))}
                                    </List>

                                </CardContent>
                            </Card>
                        ))}
                    </CardContent>
                </Card>
            ))}
        </div>
    )

}


function PlaningEngine(props: {search:(data: PlanningSearch)=>void, defaultDate: Date, defaultTerm: number }) {

    const [nbDays, setNbDays] = React.useState<number>(props.defaultTerm);
    const [startDate, setStartDate] = React.useState<Date|null>(props.defaultDate);

    const handleChangeDate = (newValue: Date | null) => {
        setStartDate(newValue);
        search();
    };
    const reInit = () => {
        setNbDays(props.defaultTerm);
        setStartDate(props.defaultDate);
        search();
    }
    const search = () => {
        props.search({
            date: moment(startDate).format('yyyy-MM-DD'),
            term: nbDays
        })
    }


    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Enter') {
            return;
        }
        handleBlur();
    }
    const  handleBlur = () => search();


    return (
        <Card sx={{mb:'30px'}} elevation={6}>
            <CardHeader title={'Recherche'}/>
            <CardContent id="motor-recipe-cardContent" sx={{ display: 'flex', flexWrap: 'wrap'}}>

                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                        label="Date de début du planning"
                        inputFormat="DD/MM/yyyy"
                        value={startDate}
                        onChange={handleChangeDate}
                        disableMaskedInput={true}
                        renderInput={(params) => {
                            params.disabled = true;
                            return (<TextField {...params} />)
                        }}
                    />
                </LocalizationProvider>
                <TextField
                    label="Nombre de jours"
                    sx={{ m: 1, width: '25ch' }}
                    value={nbDays}
                    onChange={(e) => setNbDays(parseInt(e.target.value, 10))}
                    onKeyDown={handleKeyDown} onBlur={handleBlur}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Jours</InputAdornment>,
                    }}
                />

                <div>
                    <Button variant="contained" size={"small"} color="primary" onClick={search}>
                        <SearchIcon fontSize="inherit" />
                    </Button>
                    <Button  variant="contained" size={"small"} color="secondary" onClick={reInit}>
                        <ReplayIcon fontSize="inherit" />
                    </Button>
                </div>
            </CardContent>
        </Card>
    )
}
