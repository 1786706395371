import AuthStorage from "./AuthStorage";

class HttpClientHelper {

  static basePath = process.env.REACT_APP_WEB_SERVICE;

  static call(method: string, url: string, data: any | null = null): Promise<Response> {
    let init: RequestInit = {
      method,
      headers: {'Content-Type': 'application/json'},
    }
    if (method !== 'GET') {
      init.body = JSON.stringify(data);
    }

    return fetch(new Request(
      HttpClientHelper.basePath + url,
      init
    ));
  }

  static callWithAuth(method: string, url: string, data: any | null = null) {
    const authStorage = new AuthStorage();
    const userAuthData = authStorage.getUserData()
    if (userAuthData === null) {
      throw new Error('No auth information');
    }
    const delayToUse = (userAuthData?.expiration || 0) - Math.round(Date.now() / 1000);
    if (delayToUse < 0) {
      authStorage.removeUserData();

      throw new Error('Error !');
    }

    let init: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userAuthData.jwt
      },
    }
    if (method !== 'GET') {
      init.body = JSON.stringify(data);
    }

    return fetch(new Request(HttpClientHelper.basePath + url, init))
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      throw response
    });
  }

}

export default HttpClientHelper;
