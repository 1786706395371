import HttpClientHelper from "../Common/HttpClientHelper";

export default abstract class RecipeRepository<InputT, OutputT, InputSearchT> {
    abstract readonly getEntityUrl: string;

    abstract dataToEntity(data: any): OutputT;

    listFull: null|Promise<OutputT[]> = null;
    lastUpdate: number = 0

    find(): Promise<OutputT[]> {
      const currentTime = Math.round(new Date().getTime() / 1000);
      if (null === this.listFull || (currentTime - this.lastUpdate> 10 )) {
        this.lastUpdate = currentTime
        this.listFull = new Promise<OutputT[]>((resolve)=>(
          HttpClientHelper.callWithAuth('GET', '/' + this.getEntityUrl)
            .then((data: InputT[])=> {
              resolve(data.map((line: InputT)=> this.dataToEntity(line)))
            })
        ))
      }

      return this.listFull;
    }

    findOne(id: number|string): Promise<OutputT> {
        return HttpClientHelper.callWithAuth('GET', '/' + this.getEntityUrl + '/' + id + '?depth=full')
            .then((dataInput: InputT)=> {
                //console.log(dataInput)
                return this.dataToEntity(dataInput)
            })
            ;
    }

    search(search: InputSearchT): Promise<OutputT[]> {
        return HttpClientHelper.callWithAuth('POST', '/' + this.getEntityUrl + '/search', search)
            .then((data: InputT[])=> {
                //console.log(data);
                return data.map((line: InputT)=> this.dataToEntity(line))
            })
            ;
    }

}

