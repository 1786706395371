import HttpClientHelper from "../../Common/HttpClientHelper";
import {RecipeForList, RecipeFull} from "../../Domain/Recipe/Entity";
import {RecipeInput} from "./Entity";


interface recipeSearch {
  category: {
    ids: string[] | null
  },
  ingredient: {
    ids: number[] | null
    seasons: string[] | null
  },
  recipe: {
    valorization_min: string | null,
    valorization_max: string | null,
    name: string | null,
  }
}


export default class RecipeRepository {
  static getEntityUrl: string = 'recipes';

  private static instance: RecipeRepository;

  private constructor() {
  }

  static getRepository(): RecipeRepository {

    if (!RecipeRepository.instance) {
      RecipeRepository.instance = new RecipeRepository();
    }

    return RecipeRepository.instance;
  }

  static dataToEntity(data: RecipeInput) {
    return new RecipeFull(
      data.id,
      data.name,
      data.description,
      data.categoryId,
      data.unit,
      data.portion,
      data.valuation,
      data.valuationPortion,
      data.duration,
      data.temperature,
      data.instructions,
      data.quantity,
    )
  }


  static entityToData(recipe: RecipeFull): any {
    return {
      name: recipe.name,
      categoryId: recipe.categoryId,
      duration: recipe.duration,
      description: recipe.description,
      portion: recipe.portion,

      valuation: recipe.valuation,
      valuationPortion: recipe.valuationPortion,
      temperature: recipe.temperature,
      unitId: recipe.unit?.id || null,
      instructions: recipe.instructions.map(instruction => {
        return {
          id: instruction.id,
          name: instruction.name || '',
          description: instruction.description,
          recipes: instruction.recipes.map(recipeQty => ({
            id: recipeQty.id,
            quantity: recipeQty.quantity,
            elementId: recipeQty.recipeId
          })),
          ingredients: instruction.ingredients.map(ingredientQty => ({
            id: ingredientQty.id,
            quantity: ingredientQty.quantity,
            elementId: ingredientQty.ingredientId,
            unitId: ingredientQty.unitId
          })),
        }
      })
    }
  }

  searchLegacy(catsId: string[], ingredientIds: number[], ingSeason: string | null, recipeName: string | null, valMin: string | null, valMax: string | null): Promise<RecipeForList[]> {
    let dataSearch: recipeSearch = {
      category: {
        ids: null
      },
      ingredient: {
        ids: null,
        seasons: null
      },
      recipe: {
        "valorization_min": valMin,
        "valorization_max": valMax,
        "name": recipeName,
      }
    };
    if (typeof catsId === 'object' && catsId.length > 0) {
      dataSearch.category.ids = catsId
    }
    if (typeof ingredientIds === 'object' && ingredientIds.length > 0) {
      dataSearch.ingredient.ids = ingredientIds;
    }
    if (typeof ingSeason === 'string' && ingSeason.length > 0) {
      dataSearch.ingredient.seasons = [ingSeason];
    }

    return HttpClientHelper.callWithAuth('POST', '/recipes/search', dataSearch)
      .then((data: any[]): RecipeForList[] => {
        return data.map((line: any): RecipeForList => new RecipeForList(
            line.id,
            line.name,
            line.categoryId,
            line.unit.id,
            line.portion,
            line.valuation,
            line.duration,
            line.temperature,
          )
        )
      })
      ;
  }


  static findOne(id: number | string): Promise<RecipeFull> {
    return HttpClientHelper.callWithAuth('GET', '/' + this.getEntityUrl + '/' + id + '?depth=full')
      .then((dataInput: RecipeInput) => {
        console.log(dataInput)
        return this.dataToEntity(dataInput)
      })
      ;
  }

  static update(recipe: RecipeFull, forceUpdate: boolean = false) {
    let uri = '/recipes';
    let method = 'POST';
    if (recipe.id !== null) {
      uri += '/' + recipe.id;
      method = forceUpdate ? 'PUT': 'PATCH';
    }

    return HttpClientHelper.callWithAuth(method, uri, this.entityToData(recipe))
      .then((data: any[]) => {
        console.log(data);
      })
      ;

  }
  static delete(id: number){
    return HttpClientHelper.callWithAuth('DELETE', '/recipes/' + id)
      .then((response) => {
        //: {error: string, list: {id: number, name: string}}
        console.log(response)
        return response ;
      })
      .catch((response) : any => {
        let data = response.json();
        console.log(data );
        /*
        if (400 === data.code) {
          return "Impossible de supprimer cette recette car elle est incluse dans ces recettes : " +
            data.list.map(
              info=> info.name
            ).join(', ');
        }
         */
      });
  }
}
