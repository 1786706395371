import {UnitMin} from "../Unit/Entity";

class RecipeMin {
  constructor(
    public id: number | null,
    public name: string,
  ) {
  }
}

export class RecipeForList extends RecipeMin {
  constructor(
    public id: number | null,
    public name: string,
    public categoryId: number | null,
    public unitId: number | null,
    public portion: number,
    public valuation: number,
    public duration: number | null,
    public temperature: number | null,
  ) {
    super(id, name);
  }

  getValuationByPortion(): number | null {
    if (this.valuation === null || this.portion === null) {
      return null;
    }

    return Math.round(this.valuation / this.portion * 1000) / 1000;
  }

  getInformation() {
    let content: string[] = [];
    if (this.duration !== null && this.duration > 0) {
      content.push('Duration : ' + this.duration + ' mins')
    }

    if (this.temperature !== null && this.temperature > 0) {
      content.push('Température : ' + this.temperature + '°C')
    }

    return content.join(' - ');
  }

}

export class RecipeFull extends RecipeForList {

  constructor(
    public id: number | null,
    public name: string,
    public description: string | null,
    public categoryId: number | null,
    public unit: UnitMin | null,
    public portion: number,
    public valuation: number,
    public valuationPortion: number,
    public duration: number | null,
    public temperature: number | null,
    public instructions: Instruction[],
    public quantity: {
      recipes: any[],
      ingredients: {
        ingredientId: number,
        ingredientName: string,
        ingredientVal: { quantity: number, unitId: number, value: number }
        quantity: number
        unitAbbreviation: string
        unitId: number
        unitName: string
      }[]
    },
  ) {
    super(id, name, categoryId, null === unit ? null : unit.id, portion, valuation, duration, temperature);
  }
}

export interface Instruction {
  id: number | null,
  name: string,
  order: number,
  created: string,
  modified: string,
  description: string,
  ingredients: IngredientQty[],
  recipes: RecipeQty[],
}

export interface IngredientQty {
  id: number | null,
  ingredientId: number,
  unitId: number,
  quantity: number,

  ingredientName: string,
  ingredientValPor: number | null,
  ingredientValObject: { quantity: number, unitId: number, value: number }

  unitAbbreviation: string,
  unitName: string,
}

export interface RecipeQty {
  id: number | null,
  recipeId: number,
  quantity: number,

  recipeName: string,
  recipeValPor: number,
}


export function EmptyRecipe(): RecipeFull {
  return new RecipeFull(
    null,
    '',
    null,
    null,
    null,
    1,
    0,
    0,
    null,
    null,
    [],
    {
      recipes : [],
      ingredients: []
    }
  )
}