import React from "react";
import {
    Button,
    Card, CardContent, CardHeader
} from "@mui/material";

import TableComponent, {
    DeleteActionProperty,
    EditActionProperty,
    TableComponentContent
} from "../../Components/TableComponent";
import UnitsRepository from "../../Repository/Unit";
import AddIcon from "@mui/icons-material/Add";
import {useHistory} from "react-router-dom";


export default function UnitListCard() {

    const history = useHistory();
    const [list, setList] = React.useState<TableComponentContent[]>([])

    const columns = [
        'Type',
        'Nom',
        'Abréviation'
    ];

    React.useEffect(()=>{
        UnitsRepository.getRepository().find()
            .then((list) => {
                return setList(
                    list.map((unit) => {
                        return {
                            key: unit.id,
                            data: [
                                unit.name,
                                unit.getType(),
                                unit.shortName
                            ]
                        }
                    })
                )
            })

    }, []) // <-- empty dependency array

    const handleActions = function (action: string, key: string|number): void {
        if (action === 'edit') {
            alert('not yet');
        }else if (action === 'view') {
            history.push('/units/'+ key + '/view');
        }else if (action === 'plan') {
            alert('not yet');
            //history.push('/recipes/'+ key + '/edit');
        }else if (action === 'delete') {
            alert('not yet delete');
            //history.push('/recipes/'+ key + '/edit');
        }
    }

    return (
      <div id={"UnitListCard"}>
          <Card elevation={6}>
              <CardHeader title={'Liste des unités'} action={ <Button variant="contained" color={"success"} endIcon={<AddIcon />}>Ajouter</Button>}/>
              <CardContent>
                  <TableComponent columns={columns} actions={[new EditActionProperty(), new DeleteActionProperty()]} actionsHandler={handleActions} content={list}/>
              </CardContent>
          </Card>
      </div>
    );
}
