import React from "react";
import {RouteComponentProps} from "react-router-dom";
import RecipeRepository from "../../Infra/Recipe/Repository";
import {RecipeFull} from "../../Domain/Recipe/Entity";
import {Box, Card, CardActions, CardContent, CardHeader, List, ListItemText, Stack, FormControlLabel, Switch} from "@mui/material";
import nl2br from "react-nl2br";

import './RecipeViewCard.css'
import RecipeIngredientsTable from "./Common/RecipeIngredientsTable";
import {computeTitle, computeValuation} from "./RecipeCommon";

export default function RecipeViewCard(props: RouteComponentProps<{id: string, action?: string}>) {
    const id  = parseInt(props.match.params.id);
    const [isPrintedVersion, setPrintVersion] = React.useState(true);
    const [showIng, setShowIng] = React.useState(false);
    //const action = props.match.params.action || 'view';
    let [recipe, setRecipe] = React.useState<RecipeFull>();

    React.useEffect(()=>{
        RecipeRepository.findOne(id)
            .then((recipe) => {
              setRecipe(recipe)
            })
        ;

    }, [id]) // <-- empty dependency array

    if ('undefined' === typeof recipe) {
        return (
            <div id={"RecipeCard"}>
                loading
            </div>
        );
    }

    return (
      <div id={"RecipeCard"}>
        <Stack spacing={{ xs: 2}} direction="row" flexWrap="wrap">
          <h1>{computeTitle(recipe, isPrintedVersion)}</h1>
          <FormControlLabel control={<Switch onChange={() => setPrintVersion(!isPrintedVersion)} checked={isPrintedVersion}/>} label="Version imprimable" />
        </Stack>

          <Box sx={{ flexDirection: isPrintedVersion ? 'column' : 'row' , display: 'flex', }}>
              <Card elevation={6} sx={{
                  flex: 4 ,
                  ...(isPrintedVersion &&
                    { marginBottom: '12px'}
                  ),
                  ...(!isPrintedVersion &&
                    { marginRight: '12px'}
                  )
              }}>
                  <CardContent>
                      {nl2br(recipe.description)}
                  </CardContent>
                  <CardActions sx={{ flexDirection: 'row', display: 'flex', }}>
                      Valorisation : <Box component={'span'} sx={{ flex: 1 }}/>{recipe.valuation} ({computeValuation(recipe)}/Portion)
                  </CardActions>
              </Card>
              <Box sx={{ flex: 3 }}>

                <RecipeIngredientsTable
                  instructions={recipe.instructions}
                  format={isPrintedVersion ? "list": "table"}
                  setValuationTotal={(valuationRaw: number) => {
                    setRecipe(prevState => ({
                        ...prevState,
                        valuation: Math.round(valuationRaw*1000)/1000
                      } as RecipeFull)
                    )}
                  }
                />
              </Box>
          </Box>
          <Box sx={{ flexDirection: 'column', display: 'flex'}} className={'instructions'}>
            {isPrintedVersion && (
              <Card elevation={3} sx={{ marginTop: '12px' }}>
                <CardHeader title={'Instructions'} action={
                  <FormControlLabel control={<Switch onChange={() => setShowIng(!showIng)} checked={showIng}/>} label="Afficher les ingredients"/>}/>
                <CardContent sx={{ flexDirection: 'column', display: 'flex', }}>
                  {recipe.instructions.map((instruction: any, index) => (
                    <Box>
                      <Box sx={{ flexDirection: 'row', display: 'flex', }}>
                        <Box sx={{ flex: 6}}>
                          <hr/>
                          {nl2br(instruction.description)}
                        </Box>
                        {( (instruction.ingredients.length > 0 && showIng) || instruction.recipes.length > 0) &&
                            <Box sx={{flex: 2, borderLeft: '1px solid black', pl: '5px'}}>
                                <List>
                                  {instruction.ingredients.length > 0 &&
                                      <React.Fragment>
                                          <ListItemText primary={'Ingredients'}/>
                                          <List>
                                            {instruction.ingredients.map((qteInfo: any, index: number) => (
                                              <ListItemText
                                                key={index}
                                                primary={qteInfo.ingredientName + ' : ' + qteInfo.quantity + ' ' + qteInfo.unitAbbreviation}/>
                                            ))}
                                          </List>
                                      </React.Fragment>
                                  }
                                  {instruction.recipes.length > 0 &&
                                      <React.Fragment>
                                          <ListItemText primary={'Recettes'}/>
                                          <List>
                                            {instruction.recipes.map((qteInfo: any,  index: number) => (
                                              <ListItemText
                                                key={index} primary={qteInfo.recipeName + ' : ' + qteInfo.quantity}/>
                                            ))}
                                          </List>
                                      </React.Fragment>
                                  }
                                </List>
                            </Box>
                        }
                      </Box>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            )}
            {!isPrintedVersion &&
              <Box>
                {recipe.instructions.map((instruction: any, index) => (
                    <Card key={'instruction-'+index} sx={{marginTop: '12px'}} elevation={3}>
                        <CardHeader title={'Étape ' + instruction.order + (instruction.name.length>0? ' - ' + instruction.name: '') }/>
                        <CardContent sx={{ flexDirection: 'row', display: 'flex', }}>
                            {( instruction.ingredients.length > 0 || instruction.recipes.length > 0) &&
                                <Box sx={{flex: 2, borderRight: '1px solid black', marginRight: '5px'}}>
                                    <List>
                                        {instruction.ingredients.length > 0 &&
                                        <React.Fragment>
                                            <ListItemText primary={'Ingredients'}/>
                                            <List>
                                                {instruction.ingredients.map((qteInfo: any, index: number) => (
                                                    <ListItemText
                                                        key={index}
                                                        primary={qteInfo.ingredientName + ' : ' + qteInfo.quantity + ' ' + qteInfo.unitAbbreviation}/>
                                                ))}
                                            </List>
                                        </React.Fragment>
                                        }
                                        {instruction.recipes.length > 0 &&
                                        <React.Fragment>
                                            <ListItemText primary={'Recettes'}/>
                                            <List>
                                                {instruction.recipes.map((qteInfo: any,  index: number) => (
                                                    <ListItemText
                                                      key={index} primary={qteInfo.recipeName + ' : ' + qteInfo.quantity}/>
                                                ))}
                                            </List>
                                        </React.Fragment>
                                        }
                                    </List>
                                </Box>
                            }
                            <Box sx={{ flex: 6 }}>
                                {nl2br(instruction.description)}
                            </Box>
                        </CardContent>
                    </Card>
                ))}
              </Box>
            }
          </Box>
      </div>
    );
}

