import React from "react";
import {RouteComponentProps} from "react-router-dom";
import InstructionElement from "./Edit/InstructionElement";

import './RecipeEditCard.css'

// mui
import {
  BottomNavigation, BottomNavigationAction,
  Box, Card,
  CardActions, CardContent,
  FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack, TextareaAutosize, Typography
} from "@mui/material";
import {InstructionHeader} from "./Edit/InstructionHeader";
import RecipeIngredientsTable from "./Common/RecipeIngredientsTable";
import {defaultNumberFormat} from "../../Common/NumberFormat";
import CategoriesRepository, {CategoryFlattenIdsName} from "../../Repository/Category";
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UnitsRepository, {Unit} from "../../Repository/Unit";
import {EmptyRecipe, Instruction, RecipeFull} from "../../Domain/Recipe/Entity";
import RecipeRepository from "../../Infra/Recipe/Repository";
import {UnitMin} from "../../Domain/Unit/Entity";

export default function RecipeEditCard(props: RouteComponentProps<{ id?: string, action?: string }>) {
  const id = undefined !== props.match.params.id ? parseInt(props.match.params.id ) : null;
  //const action = props.match.params.action || 'view';
  let [recipe, setRecipe] = React.useState<RecipeFull | null>(null);
  let [categories, setCategories] = React.useState<CategoryFlattenIdsName[]>([]);
  let [units, setUnits] = React.useState<Unit[]>([]);
  console.log(recipe);

  React.useEffect(() => {
    if(null !== id) {
      RecipeRepository.findOne(id)
        .then((result) => {
          setRecipe(result)
        })
      ;
    }else{
      setRecipe(EmptyRecipe())
    }

    CategoriesRepository.getRepository()
      .getCatsTreeAsList()
      .then((ca)=>{
        setCategories(ca)
      })

    UnitsRepository.getPortionUnit()
      .then((units)=>{
        setUnits(units)
      })

  }, [id]) // <-- empty dependency array


  if (recipe === null || categories === null) {
    return (
      <div id={"RecipeCard"}>
        loading
      </div>
    );
  }
  const instructions: Instruction[] = recipe.instructions;
  const nbStep = instructions.length;

  const updateRecipe = (data: any) => {
    setRecipe(prevState => ({
      ...prevState,
      ...data
    } as RecipeFull))
  }
  const updateRecipeUnit = (id: number|string) => {
    let numberId = typeof id === 'number' ? id: parseInt(id);
    let unit = units.filter((unit)=> unit.id === numberId).pop();
    if(undefined !== typeof unit){
      setRecipe(prevState => ({
        ...prevState,
        ...{
          unit: unit as UnitMin
        }
      } as RecipeFull))
    }
  }

  const updateInstructions = (instruction: Instruction, index: number) => {
    if (typeof instructions[index] !== 'undefined') {
      instructions[index] = instruction
    }
    setRecipe(prevState => ({
      ...prevState,
      instructions:[...instructions]
    } as RecipeFull))
  }

  const updatePosition = (indexInstruction: number, direction: 'up' | 'down') => {
    const instructionIndexToChange = indexInstruction + (direction === 'up' ? -1 : 1)
    if (typeof instructions[instructionIndexToChange] === 'undefined' || typeof instructions[indexInstruction] === 'undefined') {
      return;
    }
    const tmpOrder = instructions[indexInstruction].order;
    instructions[indexInstruction].order = instructions[instructionIndexToChange].order
    instructions[instructionIndexToChange].order = tmpOrder
    setRecipe(prevState => ({
      ...prevState,
      instructions:[...instructions].sort((one, two) => one.order - two.order)
    } as RecipeFull))
  }

  const validation = () => {
    if(recipe === null) {
      return;
    }
    RecipeRepository.update(recipe, true);
  }

  const reload = () => {
    if(null !== id) {
      RecipeRepository.findOne(id)
        .then((result) => {
          setRecipe(result)
        })
      ;
    }else{

      setRecipe(EmptyRecipe())
    }
  }

  return (
    <div id={"RecipeCard"}>
      <h1>Recette : {recipe.name} ({recipe.portion} {recipe.unit?.name|| ''})</h1>
      <Box sx={{flexDirection: 'row', display: 'flex',}}>
        <Card elevation={6} sx={{flex: 4, marginRight: '12px'}}>
          <CardContent>
            <Stack direction={"column"}>

              <Box>
                <Typography variant={"h5"} gutterBottom>Information</Typography>
                <Stack direction={"row"}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="input-name">Nom</InputLabel>
                    <OutlinedInput id="input-name" defaultValue={recipe.name} label='Nom'
                                   onBlur={(e) => updateRecipe({'name': e.target.value})}/>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={"input-category"} shrink={ true }>Catégorie </InputLabel>
                    <Select
                      id="input-category"
                      label={"Catégorie"}
                      value={recipe?.categoryId || ''}
                      displayEmpty
                      onChange={(e) => updateRecipe({'categoryId': e.target.value})}
                    >
                      <MenuItem value={''}>Aucune</MenuItem>
                      {categories.map((catData, index) => {
                        return <MenuItem key={index} value={catData.id}>{catData.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>

              <Box>
                <Typography variant={"h5"} gutterBottom>Portion</Typography>
                <Stack direction={"row"}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="input-name">Quantité</InputLabel>
                    <OutlinedInput id="input-name" defaultValue={recipe.portion} label='Nom'
                                   onBlur={(e) => updateRecipe({'portion': e.target.value})}/>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={"input-unity"}>Unité</InputLabel>
                    <Select
                      id="input-unity"
                      label={"Unité"}
                      value={recipe?.unit?.id || ''}
                      onChange={(e) => updateRecipeUnit(e.target.value)}
                    >
                      <MenuItem value={''}>Aucune</MenuItem>
                      {units.map((unit, index) => {
                        return <MenuItem key={index} value={unit.id}>{unit.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
              <Box>
                <Typography variant={"h5"} gutterBottom>Description</Typography>
                <TextareaAutosize
                  aria-label="Description"
                  minRows={7}
                  placeholder="Description"
                  defaultValue={recipe?.description || ''}
                  onBlur={e => setRecipe(prevState => ({
                    ...prevState,
                    description: e.target.value
                  } as RecipeFull))}
                  style={{width: '100%'}}
                />
              </Box>

              <Box>
                <Typography variant={"h5"} gutterBottom>Cuisson</Typography>
                <Stack direction={"row"}>

                  <FormControl fullWidth>
                    <InputLabel htmlFor="input-duration">Durée</InputLabel>
                    <OutlinedInput id="input-duration" defaultValue={recipe.duration} label='Durée'
                                   onBlur={(e) => updateRecipe({'duration': e.target.value})}/>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="input-temperature">Température</InputLabel>
                    <OutlinedInput id="input-temperature" defaultValue={recipe.temperature} label='Température'
                                   onBlur={(e) => updateRecipe({'temperature': e.target.value})}/>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardContent>
          <CardActions sx={{flexDirection: 'row', display: 'flex',}}>
            Valorisation : <Box component={'span'}
                                sx={{flex: 1}}/>{defaultNumberFormat(recipe.valuation)} ({defaultNumberFormat(recipe.valuation / recipe.portion)}/Portion)
          </CardActions>
        </Card>
        <Box sx={{flex: 3}}>

          <RecipeIngredientsTable
            instructions={instructions}
            format="table"
            setValuationTotal={(valuationRaw: number) => {
            const valuation = Math.round(valuationRaw*1000)/1000;
              setRecipe(prevState => ({
                ...prevState,
                valuation
              } as RecipeFull))
            }
          }
          />
        </Box>
      </Box>
      <Box sx={{flexDirection: 'column', display: 'flex', mb: '50px'}} className={'instructions'}>
        {instructions.map((instruction, index) => (
          <Card sx={{mt: '30px'}} elevation={6} key={'instruction-' + index}>
            <InstructionHeader stepNumber={instruction.order} action={(action) => updatePosition(index, action)}
                               first={index === 0} last={index === (nbStep - 1)}/>
            <InstructionElement instruction={instruction}
                                updateInstruction={(instruction) => updateInstructions(instruction, index)}/>
          </Card>
        ))}
      </Box>

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels >
          <BottomNavigationAction sx={{ color: 'red'}} label="Recharger la recette" icon={<RestoreIcon />} onClick={reload}/>
          <BottomNavigationAction sx={{ color: 'green'}} label="Valider"  icon={<CheckCircleOutlineIcon />} onClick={validation}/>
        </BottomNavigation>
      </Paper>
    </div>
  );
}

