import Repository from "./Repository";
import moment from 'moment';

interface PlanningInput {
    id : number,
    __state : boolean,
    name : string,
    date : string ,
    duration : number,
    modified : string,
    created : string,
    description : string,
    portion : number,
    cycle_id : number,
    cycle_edited : boolean,
    recipes : any[],
    ingredients : any[]
}

export class Planning{
    public id : number;
    public __state : boolean;
    public name : string;
    public date : string ;
    public dateObject : Date ;
    public duration : number;
    public modified : string;
    public created : string;
    public description : string;
    public portion : number;
    public cycle_id : number;
    public cycle_edited : boolean;
    public recipes : {id: number, quantity: number, recipeId: number, recipeName: string}[];
    public ingredients : {
        id: number, ingredientId: number, ingredientName: string, quantity: number, unitId: number, unitAbbreviation: string, unitName: string
    }[];

    constructor(data: PlanningInput) {
        this.id = data.id;
        this.__state = data.__state;
        this.name = data.name;
        this.date = data.date;
        this.dateObject = new Date(data.date);

        this.duration = data.duration;
        this.modified = data.modified;
        this.created = data.created;
        this.description = data.description;
        this.portion = data.portion;
        this.cycle_id = data.cycle_id;
        this.cycle_edited = data.cycle_edited;
        this.recipes = data.recipes
        this.ingredients = data.ingredients
    }

    getDate() {
        return moment(this.dateObject).format('yyyy-MM-DD');
    }

    getHour() {
        return moment(this.dateObject).format('H:mm');
    }

}

export interface PlanningsByDate {
    date: string,
    list: Planning[]
}


export interface PlanningSearch {
    date : string,
    term : number,
}


export default class PlanningRepository extends Repository<PlanningInput, Planning, PlanningSearch> {
    readonly getEntityUrl: string = 'plannings';

    static getRepository(): PlanningRepository {
        return new PlanningRepository();
    }

    dataToEntity(data: any): Planning {
        return new Planning(data);
    }

    find(): Promise<Planning[]> {
        return super.find()
    }

    searchByDate(searchData: PlanningSearch): Promise<PlanningsByDate[]> {
        return super.search(searchData)
            .then((list: Planning[]) => {
                let contentByDate: PlanningsByDate[]= [];
                let tmp: any = {};
                list.map((planning: Planning) => {
                    const [dateStr] = planning.date.split(' ');
                    if (typeof tmp[dateStr] === 'undefined') {
                        tmp[dateStr] = [];
                    }
                    return tmp[dateStr].push(planning);
                })

                Object.keys(tmp).map((key) => {
                    return contentByDate.push({
                        date: key,
                        list: tmp[key]
                    })
                });

                return contentByDate;
            })
    }

}
