import {Box, IconButton} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

export function InstructionHeader(props: { stepNumber: number, first: boolean, last: boolean, action: (action: 'up' | 'down') => void }) {
  let listButtons = [];
  if (!props.first) {
    listButtons.push(
      <IconButton
        key={'up'}
        aria-label="Monter"
        onClick={e => props.action('up')}>
        <KeyboardArrowUpIcon/>
      </IconButton>
    );
  }

  if (!props.last) {
    listButtons.push(
      <IconButton
        key={'down'}
        aria-label="Descendre"
        onClick={e => props.action('down')}>
        <KeyboardArrowDownIcon/>
      </IconButton>
    );
  }
  return (
    <Box sx={{m: '7px'}}>
      {listButtons} <strong> Étapes {props.stepNumber}</strong>
    </Box>
  )
}
