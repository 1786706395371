import {RecipeFull} from "../../Domain/Recipe/Entity";


export function computeValuation(recipe: RecipeFull): number | null {
  if (recipe.valuation === null || recipe.portion === null) {
    return null;
  }

  return Math.round(recipe.valuation / recipe.portion * 1000) / 1000;
}

export function computeTitle(recipe: RecipeFull, isPrintable: boolean): string {
  if (isPrintable) {
    return recipe.name + ' (' + recipe.portion + ' ' + recipe.unit?.name + ')';
  }
  return 'Recette : ' + recipe.name + ' (' + recipe.portion + ' ' + recipe.unit?.name + ')';
}