import React from 'react';
import {
  Divider, Drawer, ListItem, ListItemText, ListItemIcon, Box, Toolbar
} from "@mui/material";

//icons
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';

type environment = 'dev' | 'staging' | 'prod';

interface SideMenu {
  uri: string
  label: string
  icon: any
  authenticated: boolean
  environment: environment[] // 'prod' | 'dev'
}

const listMenu: SideMenu[] = [
  /*{
    uri: '/',
    label: 'Accueil',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: 'dev'
  },
  */
  {
    uri: '/recipes',
    label: 'Recettes',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev', 'staging' ,'prod']
  },
  /*
  {
    uri: '/ingredients',
    label: 'Ingredients',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev']
  },
  {
    uri: '/units',
    label: 'units',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev']
  },
  {
    uri: '/categories',
    label: 'categories',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev']
  },
  {
    uri: '/plannings',
    label: 'plannings',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev']
  },
  {
    uri: '/courses',
    label: 'courses',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev']
  },
  {
    uri: '/cycles',
    label: 'cycles',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev']
  },
  {
    uri: '/settings',
    label: 'settings',
    icon: <MenuBookIcon/>,
    authenticated: false,
    environment: ['dev']
  },
  {
    uri: '/auth',
    label: 'auth',
    icon: <MenuBookIcon/>,
    authenticated: true,
    environment: ['dev']
  },
  {
    uri: '/register',
    label: 'register',
    icon: <MenuBookIcon/>,
    authenticated: true,
    environment: ['dev']
  },
  */
]


interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

function ListItemLink(props: ListItemLinkProps) {
  const {icon, primary, to} = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary}/>
      </ListItem>
    </li>
  );
}


function SideBar(props: { state: boolean | null, width: number, setOpen: (isOpen: boolean) => void, isLoggedIn: boolean })
{

  const drawerContent = listMenu
    .filter((content: SideMenu) => content.authenticated !== props.isLoggedIn)
    .filter((content: SideMenu) => content.environment.includes(process.env.REACT_APP_ENV as environment))
    .map((content: SideMenu, index) => (
      <ListItemLink key={index} to={content.uri} primary={content.label} icon={content.icon}/>
    ))

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  return (
    <Box
      component="nav"
      sx={{width: {sm: props.width}, flexShrink: {sm: 0}}}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={props.state !== null ? props.state : true }
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {xs: 'block', sm: 'none'},
          '& .MuiDrawer-paper': {boxSizing: 'border-box', width: props.width},
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="persistent"
        anchor="left"
        open={props.state !== null ? props.state : false }
        sx={{
          display: {xs: 'none', sm: 'block'},
          '& .MuiDrawer-paper': {boxSizing: 'border-box', width: props.width},
        }}
      >
        <Toolbar/>
        <Divider/>
        {drawerContent}
      </Drawer>
    </Box>
  );
}

export default SideBar;
