import React from "react";
import {Box, Card, CardContent, CardHeader} from "@mui/material";
import UnitsRepository, {UnitsById} from "../../../Repository/Unit";
import TableComponent, {TableComponentContent} from "../../../Components/TableComponent";
import {defaultNumberFormat} from "../../../Common/NumberFormat";
import {Instruction, RecipeFull} from "../../../Domain/Recipe/Entity";
import RecipeRepository from "../../../Infra/Recipe/Repository";

export default function RecipeIngredientsTable(props: { instructions: Instruction[], format: string, setValuationTotal: (value: number) => void }) {
  const columns = [
    'Liste des ingredients',
    'Quantité',
    'Valorisation'
  ];
  let units = React.useRef<UnitsById>()
  let cache = React.useRef<{ [key: number]: RecipeFull }>({});
  let [linesTable, setLinesTable] = React.useState<TableComponentContent[]>([]);

  const computeTable = async () => {
    let listIng: { [key: string]: { ingName: string, quantity: number, unit: string, unitId: number, valuation: { quantity: number, unitId: number, value: number } } } = {};

    props.instructions.map((instruction) => {
      instruction.recipes.map((recipeQty) => {
        let recipeId = recipeQty.recipeId || 0;
        cache.current[recipeId].quantity.ingredients.map((data) => {
          if (typeof listIng[data.ingredientId + ';' + data.unitId] === "undefined") {
            listIng[data.ingredientId + ';' + data.unitId] = {
              ingName: data.ingredientName,
              quantity: data.quantity * (cache.current[recipeId].portion / recipeQty.quantity),
              unit: data.unitAbbreviation,
              unitId: data.unitId,
              valuation: data.ingredientVal
            }
          } else {
            listIng[data.ingredientId + ';' + data.unitId].quantity += data.quantity * (cache.current[recipeId].portion / recipeQty.quantity)
          }

          return data;
        })
        return recipeQty;
      })
      instruction.ingredients.map((ingredientQty) => {
        if (typeof listIng[ingredientQty.ingredientId + ';' + ingredientQty.unitId] === "undefined") {
          listIng[ingredientQty.ingredientId + ';' + ingredientQty.unitId] = {
            ingName: ingredientQty.ingredientName,
            quantity: ingredientQty.quantity,
            unit: ingredientQty.unitAbbreviation,
            unitId: ingredientQty.unitId,
            valuation: ingredientQty.ingredientValObject
          }
        } else {
          listIng[ingredientQty.ingredientId + ';' + ingredientQty.unitId].quantity += ingredientQty.quantity
        }
        return ingredientQty;
      })
      return instruction;
    })
    let valuationTotal = 0;

    const line = Object.keys(listIng).map((index) => {
      const infoIng = listIng[index];
      let valuation: number = 0;

      if (infoIng.valuation.quantity === 0 || infoIng.valuation.value === 0) {
        valuation = 0;
      } else if (infoIng.unitId === infoIng.valuation.unitId) {
        valuation = infoIng.quantity / infoIng.valuation.quantity * infoIng.valuation.value
      } else if (typeof units.current !== 'undefined' && typeof units.current[infoIng.unitId] !== "undefined" && typeof units.current[infoIng.valuation.unitId] !== "undefined") {
        const qtyByRef = infoIng.quantity * units.current[infoIng.unitId].referenceValue;
        const defQtyByRef = infoIng.valuation.quantity * units.current[infoIng.valuation.unitId].referenceValue;
        valuation = defQtyByRef === 0 ? 0 : (qtyByRef / defQtyByRef) * infoIng.valuation.value
      }
      valuationTotal += valuation;

      return {
        key: index,
        data: [
          infoIng.ingName,
          infoIng.quantity + ' ' + infoIng.unit,
          defaultNumberFormat(valuation),
        ]
      }
    })
    if (line.length > 0) {
      line.push({
        key: 'Total',
        data: [
          'Total',
          '',
          defaultNumberFormat(valuationTotal)
        ]
      })
    }
    props.setValuationTotal(valuationTotal)
    setLinesTable([...line]);
  }


  React.useEffect(() => {
    let listPromise: Promise<RecipeFull | UnitsById>[] = [];
    if (typeof units.current === 'undefined') {
      listPromise.push(UnitsRepository.getRepository().getUnitById()
        .then((result) => {
          units.current = result
          return result;
        })
      );
    }

    props.instructions
      .filter((instruction) => instruction.recipes.length !== 0)
      .map((instruction) => {
        return instruction.recipes.map(async (recipeQty) => {
          if (typeof cache.current[recipeQty.recipeId] === "undefined") {
            return RecipeRepository.findOne(recipeQty.recipeId)
              .then((result) => cache.current[recipeQty.recipeId] = result)
          }
          return Promise.resolve(cache.current[recipeQty.recipeId])
        })
      }).reduce(
      (previousValue, currentValue) => {
        previousValue.push(...currentValue)

        return previousValue;
      },
      listPromise
    )
    Promise.allSettled(listPromise).then(() => computeTable());
  }, [props.instructions])

  if (linesTable === null) {
    return (
      <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
        loading
      </Box>
    );
  }
  if(props.format === "list") {
    return (
      <Card elevation={6}>
        <CardHeader title={"Ingredients"}/>
        <CardContent>
          <ul className="no-bullet">
            {linesTable.filter((elmnt) => elmnt.key !== "Total" ).map((line, indexRow) => (
              <li key={indexRow}>{line.data[1]} {line.data[0]}</li>
            ))}
          </ul>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
      <TableComponent columns={columns} actions={"none"} actionsHandler={() => {
      }} content={linesTable}/>
    </Box>
  );
}
